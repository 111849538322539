@use "../../../variables/general" as general;
@use "../../../variables/button" as button;
@use "../../mixins" as mixins;
@use "../../../variables/form" as form;
@use "sass:math";

.p-autocomplete {
    .p-autocomplete-loader {
        right: nth(form.$inputPadding, 2);
    }

    input[type=text], button {
      border: form.$inputBorder!important;
    }

    .p-button {
      color: var(--icons-color)!important;
    }

    &.p-autocomplete-dd {
        .p-autocomplete-loader {
            right: nth(form.$inputPadding, 2) + button.$buttonIconOnlyWidth;
        }
        .p-autocomplete-input {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .p-autocomplete-dropdown {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .p-autocomplete-multiple-container {
        padding: math.div(nth(form.$inputPadding, 1), 2) nth(form.$inputPadding, 2);

        &:not(.p-disabled):hover,
        &:not(.p-disabled):focus-within {
            border-color: form.$inputHoverBorderColor;
        }

        &:not(.p-disabled).p-focus {
            @include mixins.focused-input();
        }

        .p-autocomplete-input-token {
            padding: math.div(nth(form.$inputPadding, 1), 2) 0;

            input {
                font-family: general.$fontFamily;
                font-size: general.$fontSize;
                color: var(--ig-text-color);
                padding: 0;
                margin: 0;
            }
        }

        .p-autocomplete-token {
            padding: math.div(nth(form.$inputPadding, 1), 2) nth(form.$inputPadding, 2);
            margin-right: general.$inlineSpacing;
            margin-bottom: math.div(general.$inlineSpacing, 2);
            background: general.$highlightBg;
            color: general.$highlightTextColor;
            border-radius: general.$borderRadius;

            .p-autocomplete-token-icon {
                margin-left: general.$inlineSpacing;
            }
        }
    }

    &.p-error > .p-inputtext,
    &.p-invalid > .p-inputtext {
        @include mixins.invalid-input();
    }
}

p-autocomplete.ng-dirty.ng-invalid > .p-autocomplete > .p-inputtext {
    @include mixins.invalid-input();
}

.p-autocomplete-panel {
    background: form.$inputOverlayBg;
    color: form.$inputListTextColor;
    border: form.$inputOverlayBorder;
    border-radius: general.$borderRadius;
    box-shadow: form.$inputOverlayShadow;

    .p-autocomplete-items {
        padding: form.$inputListPadding;

        .p-autocomplete-item {
            margin: form.$inputListItemMargin;
            padding: form.$inputListItemPadding;
            border: form.$inputListItemBorder;
            color: form.$inputListItemTextColor;
            background: form.$inputListItemBg;
            transition: general.$listItemTransition;
            border-radius: form.$inputListItemBorderRadius;

            &:hover,&.p-focus, &:focus-within {
                color: form.$inputListItemTextHoverColor;
                background: form.$inputListItemHoverBg;
            }

            &.p-highlight {
                color: general.$highlightTextColor;
                background: general.$highlightBg;
            }
        }
    }
}
