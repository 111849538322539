@use "../../../variables/general" as general;
@use "../../../variables/form" as form;
@use "../../../variables/misc" as misc;
.p-scrolltop {
    width: misc.$scrollTopWidth;
    height: misc.$scrollTopHeight;
    border-radius: misc.$scrollTopBorderRadius;
    box-shadow: form.$inputOverlayShadow;
    transition: general.$actionIconTransition;

    &.p-link {
        background: misc.$scrollTopBg;

        &:hover, &:focus-within {
            background: misc.$scrollTopHoverBg;
        }
    }

    .p-scrolltop-icon {
        font-size: misc.$scrollTopFontSize;
        color: misc.$scrollTopTextColor;
    }
}
