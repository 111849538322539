@use "../../../variables/general" as general;
@use "../../../variables/panel" as panel;
.p-terminal {
	background: panel.$panelContentBg;
	color: panel.$panelContentTextColor;
	border: panel.$panelContentBorder;
	padding: panel.$panelContentPadding;

	.p-terminal-input {
		font-size: general.$fontSize;
		font-family: general.$fontFamily;
	}
}
