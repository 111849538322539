@use "typo.panel.vars" as typopanelvars;
@use "typo.panel.mixin" as typopanelmixin;
// Panel Class to Root node activates the panel Styles
@mixin typoPanelClasses {
  @include typopanelmixin.igTypoContent();

  a {
    color: var(--link-color);
  }


  h1 {
    @include typopanelmixin.igTypoHeadline("size-11", "bold");
  }

  h2 {
    @include typopanelmixin.igTypoHeadline("size-09", "bold");
  }

  h3 {
    @include typopanelmixin.igTypoHeadline("size-07", "bold");
  }

  h4 {
    @include typopanelmixin.igTypoHeadline("size-06", "bold");
  }

  h5 {
    @include typopanelmixin.igTypoHeadline("size-05", "bold");
  }

  h6 {
    @include typopanelmixin.igTypoHeadline("size-05", "bold");
  }


  strong,
  .ig-text-strong {
    font-weight: typopanelvars.$font-weight-bold;
  }

  small,
  .ig-text-small {
    font-size: typopanelvars.$font-size-content-small;
  }

  .ig-text-large,
  .ig-text-intro {
    font-size: typopanelvars.$font-size-content-large;
  }

  .ig-text-tiny {
    font-size: typopanelvars.$font-size-content-tiny;
  }

  label,
  .ig-label {
    font-size: typopanelvars.$font-size-form-label;
    font-weight: typopanelvars.$font-weight-normal;

    &.ig-label--is-selected {
      font-weight: typopanelvars.$font-weight-bold;
    }
  }
  .ig-label-title {
    font-size: typopanelvars.$font-size-form-label-large;
    font-weight: typopanelvars.$font-weight-bold;
    display: block;

    sup {
      vertical-align: text-top;
    }
  }

}
