@use "../../../variables/general" as general;
@use "../../../variables/data" as data;
.p-paginator {
    background: data.$paginatorBg;
    color: data.$paginatorTextColor;
    border: data.$paginatorBorder;
    border-width: data.$paginatorBorderWidth;
    padding: data.$paginatorPadding;
    border-radius: general.$borderRadius;

    .p-paginator-first,
    .p-paginator-prev,
    .p-paginator-next,
    .p-paginator-last {
        background-color: data.$paginatorElementBg;
        border: data.$paginatorElementBorder;
        color: data.$paginatorElementIconColor;
        min-width: data.$paginatorElementWidth;
        height: data.$paginatorElementHeight;
        margin: data.$paginatorElementMargin;
        transition: general.$listItemTransition;
        border-radius: data.$paginatorElementBorderRadius;

        &:not(.p-disabled):not(.p-highlight):hover,
        &:not(.p-disabled):not(.p-highlight):focus-within {
            background: data.$paginatorElementHoverBg;
            border-color: data.$paginatorElementHoverBorderColor;
            color: data.$paginatorElementIconHoverColor;
        }
    }

    .p-paginator-first {
        border-top-left-radius: general.$borderRadius;
        border-bottom-left-radius: general.$borderRadius;
    }

    .p-paginator-last {
        border-top-right-radius: general.$borderRadius;
        border-bottom-right-radius: general.$borderRadius;
    }

    .p-dropdown {
        margin-left: general.$inlineSpacing;
        height: data.$paginatorElementHeight;

        .p-dropdown-label {
            padding-right: 0;
        }
    }

    .p-paginator-current {
        background-color: data.$paginatorElementBg;
        border: data.$paginatorElementBorder;
        color: data.$paginatorElementIconColor;
        min-width: data.$paginatorElementWidth;
        height: data.$paginatorElementHeight;
        margin: data.$paginatorElementMargin;
        padding: 0 general.$inlineSpacing;
    }

    .p-paginator-pages {
        .p-paginator-page {
            background-color: data.$paginatorElementBg;
            border: data.$paginatorElementBorder;
            color: data.$paginatorElementIconColor;
            min-width: data.$paginatorElementWidth;
            height: data.$paginatorElementHeight;
            margin: data.$paginatorElementMargin;
            transition: general.$listItemTransition;
            border-radius: data.$paginatorElementBorderRadius;

            &.p-highlight {
                background: general.$highlightBg;
                border-color: general.$highlightBg;
                color: general.$highlightTextColor;
            }

            &:not(.p-highlight):hover,
            &:not(.p-highlight):focus-within {
                background: data.$paginatorElementHoverBg;
                border-color: data.$paginatorElementHoverBorderColor;
                color: data.$paginatorElementIconHoverColor;
            }
        }
    }
}
