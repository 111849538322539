@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/panel" as panel;
.p-accordion {
    .p-accordion-header {
        .p-accordion-header-link {
            padding: panel.$accordionHeaderPadding;
            border: panel.$accordionHeaderBorder;
            color: panel.$accordionHeaderTextColor;
            background: panel.$accordionHeaderBg;
            font-weight: panel.$accordionHeaderFontWeight;
            border-radius: general.$borderRadius;
            transition: general.$listItemTransition;

            .p-accordion-toggle-icon {
              font-size: 13px;
            }
        }

        &:not(.p-disabled) {
            .p-accordion-header-link {
                &:focus {
                    @include mixins.focused();
                }
            }
        }

        &:not(.p-highlight):not(.p-disabled):hover,
        &:not(.p-highlight):not(.p-disabled):focus-within {
            .p-accordion-header-link {
                background: panel.$accordionHeaderHoverBg;
                border-color: panel.$accordionHeaderHoverBorderColor;
                color: panel.$accordionHeaderTextHoverColor;
            }
        }

        &:not(.p-disabled).p-highlight {
            .p-accordion-header-link {
                background: panel.$accordionHeaderActiveBg;
                border-color: panel.$accordionHeaderActiveBorderColor;
                color: panel.$accordionHeaderTextActiveColor;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }

            &:hover, &:focus-within {
                .p-accordion-header-link {
                    border-color: panel.$accordionHeaderActiveHoverBorderColor;
                    background: panel.$accordionHeaderActiveHoverBg;
                    color: panel.$accordionHeaderTextActiveHoverColor;
                }
            }
        }
    }

    .p-accordion-content {
        padding: panel.$accordionContentPadding;
        border: panel.$accordionContentBorder;
        background: panel.$accordionContentBg;
        color: panel.$accordionContentTextColor;
        border-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: general.$borderRadius;
        border-bottom-left-radius: general.$borderRadius;
    }

    p-accordiontab {
        .p-accordion-tab {
            margin-bottom: panel.$accordionSpacing;
        }

        @if panel.$accordionSpacing == 0 {
            .p-accordion-header {
                .p-accordion-header-link {
                    border-radius: 0;
                }
            }

            .p-accordion-content {
                border-radius: 0;
            }

            &:not(:first-child) {
                .p-accordion-header {
                    .p-accordion-header-link {
                        border-top: 0 none;
                    }

                    &:not(.p-highlight):not(.p-disabled):hover,
                    &:not(.p-highlight):not(.p-disabled):focus-within,
                    &:not(.p-disabled).p-highlight:hover,
                    &:not(.p-disabled).p-highlight:focus-within {
                        .p-accordion-header-link {
                            border-top: 0 none;
                        }
                    }
                }
            }

            &:first-child {
                .p-accordion-header {
                    .p-accordion-header-link {
                        border-top-right-radius: general.$borderRadius;
                        border-top-left-radius: general.$borderRadius;
                    }
                }
            }

            &:last-child {
                .p-accordion-header:not(.p-highlight) {
                    .p-accordion-header-link {
                        border-bottom-right-radius: general.$borderRadius;
                        border-bottom-left-radius: general.$borderRadius;
                    }
                }

                .p-accordion-content {
                    border-bottom-right-radius: general.$borderRadius;
                    border-bottom-left-radius: general.$borderRadius;
                }
            }
        }
    }
}
