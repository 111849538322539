@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/form" as form;
@use "sass:math";

.p-inputswitch {
    width: form.$inputSwitchWidth;
    height: form.$inputSwitchHeight;

    .p-inputswitch-slider {
        background: form.$inputSwitchSliderOffBg;
        transition: general.$formElementTransition;
        border-radius: form.$inputSwitchBorderRadius;
        // box-shadow: 0px 0px 2px 1px form.$inputSwitchHandleOffBg;

        &:before {
            background: form.$inputSwitchHandleOffBg;
            width: form.$inputSwitchHandleWidth;
            height: form.$inputSwitchHandleHeight;
            left: form.$inputSwitchSliderPadding;
            margin-top: -1 * math.div(form.$inputSwitchHandleHeight, 2);
            border-radius: form.$inputSwitchHandleBorderRadius;
            transition-duration: general.$transitionDuration;
            border: var(--high-contrast-border);
        }
    }

    &.p-inputswitch-checked {
        .p-inputswitch-slider:before {
            transform: translateX(form.$inputSwitchHandleWidth);
        }
    }

    &.p-focus {
        .p-inputswitch-slider {
            @include mixins.focused();
        }
    }

    &:not(.p-disabled):hover,
    &:not(.p-disabled):focus-within {
        .p-inputswitch-slider {
            background: form.$inputSwitchSliderOffHoverBg;
        }
    }

    &.p-inputswitch-checked {
        .p-inputswitch-slider {
            background: form.$inputSwitchSliderOnBg;

            &:before {
                background: form.$inputSwitchHandleOnBg;
             }
        }

        &:not(.p-disabled):hover,
        &:not(.p-disabled):focus-within {
            .p-inputswitch-slider {
                background: form.$inputSwitchSliderOnHoverBg;
            }
        }
    }

    &.p-error,
    &.p-invalid {
        @include mixins.invalid-input();
    }
}

p-inputswitch.ng-dirty.ng-invalid.ng-touched > .p-inputswitch {
    @include mixins.invalid-input();
}
