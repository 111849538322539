@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/button" as button;
.p-selectbutton {
    .p-button {
        background: button.$toggleButtonBg;
        border: button.$toggleButtonBorder;
        color: button.$toggleButtonTextColor;
        transition: general.$formElementTransition;

        .p-button-icon-left,
        .p-button-icon-right {
            color: button.$toggleButtonIconColor;
        }

        &:not(.p-disabled):not(.p-highlight):hover,
        &:not(.p-disabled):not(.p-highlight):focus-within {
            background: button.$toggleButtonHoverBg;
            border-color: button.$toggleButtonHoverBorderColor;
            color: button.$toggleButtonTextHoverColor;

            .p-button-icon-left,
            .p-button-icon-right {
                color: button.$toggleButtonIconHoverColor;
            }
        }

        &.p-highlight {
            background: button.$toggleButtonActiveBg;
            border-color: button.$toggleButtonActiveBorderColor;
            color: button.$toggleButtonTextActiveColor;

            .p-button-icon-left,
            .p-button-icon-right {
                color: button.$toggleButtonIconActiveColor;
            }

            &:hover, &:focus-within {
                background: button.$toggleButtonActiveHoverBg;
                border-color: button.$toggleButtonActiveHoverBorderColor;
                color: button.$toggleButtonTextActiveHoverColor;

                .p-button-icon-left,
                .p-button-icon-right {
                    color: button.$toggleButtonIconActiveHoverColor;
                }
            }
        }
    }

    &.p-error > .p-button,
    &.p-invalid > .p-button {
        @include mixins.invalid-input();
    }
}

p-selectbutton.ng-dirty.ng-invalid > .p-selectbutton > .p-button {
    @include mixins.invalid-input();
}
