@use "form" as form;
@use "panel" as panel;
/// Border of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContentBorder: var(--high-contrast-border-width) var(--high-contrast-border-style) var(--high-contrast-border-color);

/// Background of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContentBg: panel.$panelContentBg;

/// Box shadow of an overlay container element such as dialog or overlaypanel
/// @group overlay
$overlayContainerShadow: var(--box-shadow);

/// Background of a dialog header
/// @group overlay
$dialogHeaderBg: transparent;

/// Border of a dialog header
/// @group overlay
$dialogHeaderBorder: 1px solid var(--ig-color-card-border);

/// Text color of a dialog header
/// @group overlay
$dialogHeaderTextColor: panel.$panelHeaderTextColor;

/// Font weight of a dialog header
/// @group overlay
$dialogHeaderFontWeight: 600;

/// Font size of a dialog header
/// @group overlay
$dialogHeaderFontSize: 1.25rem;

/// Padding of a dialog header
/// @group overlay
$dialogHeaderPadding: 1.5rem;

/// Padding of a dialog content
/// @group overlay
$dialogContentPadding: 0 1.5rem;

/// Border of a dialog footer
/// @group overlay
$dialogFooterBorder: 1px solid var(--ig-color-card-border);

/// Padding of a dialog footer
/// @group overlay
$dialogFooterPadding: 1.5rem;

/// Background of a tooltip
/// @group overlay
$tooltipBg: var(--text-color);

/// Text color of a tooltip
/// @group overlay
$tooltipTextColor: var(--ig-color-white);

/// Padding of a tooltip
/// @group overlay
$tooltipPadding: form.$inputPadding;
