@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/button" as button;
@use "../../../variables/data" as data;
@use "../../../variables/panel" as panel;
.fc {
    .fc-view-container {
        th {
            background: panel.$panelHeaderBg;
            border: panel.$panelHeaderBorder;
            color: panel.$panelHeaderTextColor;
        }

        td {
            &.fc-widget-content {
                background: panel.$panelContentBg;
                border: panel.$panelContentBorder;
                color: panel.$panelContentTextColor;
            }

            &.fc-head-container {
                border: panel.$panelHeaderBorder;
            }
        }

        .fc-row {
            border-right: panel.$panelHeaderBorder;
        }

        .fc-event {
            background: data.$fullCalendarEventBg;
            border: data.$fullCalendarEventBorder;
            color: data.$fullCalendarEventTextColor;
        }

        .fc-divider {
            background: panel.$panelHeaderBg;
            border: panel.$panelHeaderBorder;
        }
    }

    .fc-toolbar {
        .fc-button {
            color: button.$buttonTextColor;
            background: button.$buttonBg;
            border: button.$buttonBorder;
            font-size: general.$fontSize;
            transition: general.$formElementTransition;
            border-radius: general.$borderRadius;
            display: flex;
            align-items: center;

            &:enabled {
              &:hover, &:focus-within {
                background: button.$buttonHoverBg;
                color: button.$buttonTextHoverColor;
                border-color: button.$buttonHoverBorderColor;
              }
            }

            &:enabled:active {
                background: button.$buttonActiveBg;
                color: button.$buttonTextActiveColor;
                border-color: button.$buttonActiveBorderColor;

                &:focus {
                    @include mixins.focused();
                }
            }

            // .fc-icon-chevron-left {
            //     font-family: 'PrimeIcons' !important;
            //     text-indent: 0;
            //     @include icon_override("\e900");
            //     font-size: general.$primeIconFontSize;
            // }

            // .fc-icon-chevron-right {
            //     font-family: 'PrimeIcons' !important;
            //     text-indent: 0;
            //     @include icon_override("\e901");
            //     font-size: general.$primeIconFontSize;
            // }

            &:focus {
                @include mixins.focused();
            }

            &.fc-dayGridMonth-button,
            &.fc-timeGridWeek-button,
            &.fc-timeGridDay-button {
                background: button.$toggleButtonBg;
                border: button.$toggleButtonBorder;
                color: button.$toggleButtonTextColor;
                transition: general.$formElementTransition;

                &:hover, &:focus-within {
                    background: button.$toggleButtonHoverBg;
                    border-color: button.$toggleButtonHoverBorderColor;
                    color: button.$toggleButtonTextHoverColor;
                }

                &.fc-button-active {
                    background: button.$toggleButtonActiveBg;
                    border-color: button.$toggleButtonActiveBorderColor;
                    color: button.$toggleButtonTextActiveColor;

                    &:hover, &:focus-within {
                        background: button.$toggleButtonActiveHoverBg;
                        border-color: button.$toggleButtonActiveHoverBorderColor;
                        color: button.$toggleButtonTextActiveHoverColor;
                    }
                }

                &:focus {
                    @include mixins.focused();
                    z-index: 1;
                }
            }
        }

        .fc-button-group {
            .fc-button {
                border-radius: 0;

                &:first-child {
                    border-top-left-radius: general.$borderRadius;
                    border-bottom-left-radius: general.$borderRadius;
                }

                &:last-child {
                    border-top-right-radius: general.$borderRadius;
                    border-bottom-right-radius: general.$borderRadius;
                }
            }
        }
    }
}
