@use "../../../variables/general" as general;
@use "../../../variables/form" as form;
@use "../../../variables/overlay" as overlay;
.p-tooltip {
    .p-tooltip-text {
        background: overlay.$tooltipBg;
        color: overlay.$tooltipTextColor;
        padding: overlay.$tooltipPadding;
        box-shadow: form.$inputOverlayShadow;
        border-radius: general.$borderRadius;
    }

    &.p-tooltip-right {
        .p-tooltip-arrow {
            border-right-color: overlay.$tooltipBg;
        }
    }

    &.p-tooltip-left {
        .p-tooltip-arrow {
            border-left-color: overlay.$tooltipBg;
        }
    }

    &.p-tooltip-top {
        .p-tooltip-arrow {
            border-top-color: overlay.$tooltipBg;
        }
    }

    &.p-tooltip-bottom {
        .p-tooltip-arrow {
            border-bottom-color: overlay.$tooltipBg;
        }
    }
}
