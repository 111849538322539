@use "../../../variables/panel" as panel;
@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/overlay" as overlay;
@use "../../../public/base_variables" as base_variables;

.p-dialog {
    border-radius: base_variables.$igBorderRadiusL;
    box-shadow: overlay.$overlayContainerShadow;
    border: 0px solid;
    background: overlay.$overlayContentBg;


    .p-dialog-header {
        border-bottom: overlay.$dialogHeaderBorder;
        background: overlay.$dialogHeaderBg;
        color: overlay.$dialogHeaderTextColor;
        padding: overlay.$dialogHeaderPadding;
        border-top-right-radius: general.$borderRadius;
        border-top-left-radius: general.$borderRadius;

        .p-dialog-title {
            font-weight: overlay.$dialogHeaderFontWeight;
            font-size: overlay.$dialogHeaderFontSize;
        }

        .p-dialog-header-icon {
            @include mixins.action-icon();
            margin-right: general.$inlineSpacing;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .p-dialog-content {
        color: panel.$panelContentTextColor;
        padding: overlay.$dialogContentPadding;
    }

    .p-dialog-footer {
        border-top:  overlay.$dialogFooterBorder;
        background: overlay.$overlayContentBg;
        color: panel.$panelFooterTextColor;
        padding: overlay.$dialogFooterPadding;
        text-align: right;
        border-bottom-right-radius: base_variables.$igBorderRadiusL;
        border-bottom-left-radius: base_variables.$igBorderRadiusL;

        button {
            margin: 0 general.$inlineSpacing 0 0;
            width: auto;
        }
    }

    &.p-confirm-dialog {
        .p-confirm-dialog-icon {
            font-size: general.$primeIconFontSize * 2;
        }

        .p-confirm-dialog-message {
            margin-left: general.$inlineSpacing * 2;
        }
    }
}

.p-dialog-mask.p-component-overlay {
    background-color: general.$maskBg;
}
