@use "../../../variables/general" as general;
@use "../../../variables/panel" as panel;
.p-splitter {
    border: panel.$panelContentBorder;
    background: panel.$panelContentBg;
    border-radius: general.$borderRadius;
    color: panel.$panelContentTextColor;

    .p-splitter-gutter {
        transition: general.$actionIconTransition;
        background: panel.$splitterGutterBg;

        .p-splitter-gutter-handle {
            background: panel.$splitterGutterHandleBg;
        }
    }

    .p-splitter-gutter-resizing {
        background: panel.$splitterGutterHandleBg;
    }
}