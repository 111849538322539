@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/panel" as panel;
.p-panel {
    .p-panel-header {
        border: panel.$panelHeaderBorder;
        padding: panel.$panelHeaderPadding;
        background: panel.$panelHeaderBg;
        color: panel.$panelHeaderTextColor;
        border-top-right-radius: general.$borderRadius;
        border-top-left-radius: general.$borderRadius;

        .p-panel-title {
            font-weight: panel.$panelHeaderFontWeight;
        }

        .p-panel-header-icon {
            @include mixins.action-icon();
        }
    }

    &.p-panel-toggleable {
        .p-panel-header {
            padding: panel.$panelToggleableHeaderPadding;
        }
    }

    .p-panel-content {
        padding: panel.$panelContentPadding;
        border: panel.$panelContentBorder;
        background: panel.$panelContentBg;
        color: panel.$panelContentTextColor;
        border-bottom-right-radius: general.$borderRadius;
        border-bottom-left-radius: general.$borderRadius;
        border-top: 0 none;
    }

    .p-panel-footer {
        padding: panel.$panelFooterPadding;
        border: panel.$panelFooterBorder;
        background: panel.$panelFooterBg;
        color: panel.$panelFooterTextColor;
        border-top: 0 none;
    }
}
