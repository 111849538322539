@use "../iconfonts/gsiconfont.mixins" as gsiconfontmixins;
@use "sass:meta";
/**
 * this are the complete public foundation styles including:
 * - css rules
 * - vars
 * - mixins
 */
@include meta.load-css('./packages/ingrid/src/lib/foundations/colors/colors.public.scss');
@include meta.load-css('./packages/ingrid/src/lib/foundations/typographie/typographie.public.scss');
@include meta.load-css('./packages/ingrid/src/lib/foundations/logo/logo.public.scss');
@include meta.load-css('./packages/ingrid/src/lib/foundations/forms/forms.public.scss');

i.tooltip-icon {
  font-size: 1.5em;
  line-height: 1;
  cursor: pointer;
  position: relative;
  bottom: -0.125em;
  margin-top: -0.5em;
  margin-left: -0.2em;
  display: inline-block;

  &:before {
    @include gsiconfontmixins.gicon('a120_info_outline');
    color: var(--ig-color-label);
  }
}
