@use "typo.panel.vars" as typopanelvars;
@use "sass:map";

//Mixin
@mixin igTypoHeadline($size, $weight) {
  font-family: typopanelvars.$font-family-headline;
  font-weight: map.get(typopanelvars.$font-weights, $weight);
  font-size: map.get(typopanelvars.$font-sizes, $size);
  line-height: normal;
}
@mixin igTypoContent() {
  font-family: typopanelvars.$font-family-content;
  font-weight: typopanelvars.$font-weight-normal;
  font-size: typopanelvars.$font-size-content-default;
  line-height: typopanelvars.$base-line-height;
}
@mixin igTypoLargeNumbers() {
  font-weight: typopanelvars.$font-weight-bold;
  font-size: typopanelvars.$font-size-card-highlightValue;
  line-height: 1;
}

@mixin igTypoPanelTitle() {
  font-weight: typopanelvars.$font-weight-bold;
  font-size: map.get(typopanelvars.$font-sizes, "size-07");
  line-height: normal;
}
