@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/menu" as menu;
.p-breadcrumb {
    background: menu.$breadcrumbBg;
    border: menu.$breadcrumbBorder;
    border-radius: general.$borderRadius;
    padding: menu.$breadcrumbPadding;

    ul {
        li {
            .p-menuitem-link {
                transition: general.$listItemTransition;
                border-radius: general.$borderRadius;

                &:focus {
                    @include mixins.focused();
                }

                .p-menuitem-text {
                    color: menu.$breadcrumbItemTextColor;
                }

                .p-menuitem-icon {
                    color: menu.$breadcrumbItemIconColor;
                }
            }

            &.p-breadcrumb-chevron {
                margin: 0 general.$inlineSpacing 0 general.$inlineSpacing;
                color: menu.$breadcrumbSeparatorColor;
            }

            &:last-child {
                .p-menuitem-text {
                    color: menu.$breadcrumbLastItemTextColor;
                }

                .p-menuitem-icon {
                    color: menu.$breadcrumbLastItemIconColor;
                }
            }
        }
    }
}
