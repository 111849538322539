@use "typo.panel.classes" as typoPanelClasses;

body.ig-panel,
.ig-panel {
  @include typoPanelClasses.typoPanelClasses();
}

body.sb-show-main {
  &.ig-panel,
  .ig-panel {
    @include typoPanelClasses.typoPanelClasses();
  }
}
