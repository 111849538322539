@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/menu" as menu;
.p-tieredmenu {
    padding: menu.$verticalMenuPadding;
    background: menu.$menuBg;
    color: menu.$menuTextColor;
    border: menu.$menuBorder;
    border-radius: general.$borderRadius;
    width: menu.$menuWidth;

    .p-menuitem-link {
        @include mixins.menuitem-link();

        .p-menuitem-icon:not(.fa) {
            font-size: 1.5em;
            margin-right: 0.25rem;
            margin-left: -0.25rem;
        }
    }

    &.p-tieredmenu-overlay {
        background: menu.$overlayMenuBg;
        border: menu.$overlayMenuBorder;
        box-shadow: menu.$overlayMenuShadow;
    }

    .p-submenu-list {
        padding: menu.$verticalMenuPadding;
        background: menu.$overlayMenuBg;
        border: menu.$overlayMenuBorder;
        box-shadow: menu.$overlayMenuShadow;
        border-radius: general.$borderRadius;
    }

    .p-menuitem {
        margin: menu.$verticalMenuitemMargin;

        &:last-child {
            margin: 0;
        }

        &.p-menuitem-active {
            > .p-menuitem-link {
                background: menu.$menuitemActiveBg;

                .p-menuitem-text {
                    color: menu.$menuitemTextActiveColor;
                }

                .p-menuitem-icon, .p-submenu-icon {
                    color: menu.$menuitemIconActiveColor;
                }
            }
        }
    }

    .p-focus {
      background: menu.$menuitemHoverBg;
      &>.p-menuitem-content {
        .p-menuitem-text {
          color: menu.$menuitemTextHoverColor;
        }
        .p-menuitem-icon, .p-submenu-icon {
          color: menu.$menuitemIconHoverColor;
        }
      }
    }

    .p-menu-separator {
        border-top: general.$divider;
        margin: menu.$menuSeparatorMargin;
    }

    .p-submenu-icon {
        font-size: menu.$menuitemSubmenuIconFontSize;
    }
}
