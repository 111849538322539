@use "../public/mix" as mix;
@use "../public/base_variables" as base_variables;
@use "../variables/panel" as panel;
.p-accordion.p-component {
  padding: 0;
  background-color: transparent;
}

p-accordiontab {
  margin-bottom: 10px;
  display: block;
  &:last-of-type {
    margin: 0;
  }


  .p-accordion-tab {
    background: panel.$accordionHeaderBg;
    color: panel.$accordionHeaderTextColor;
    transition: all .15s ease;
    border-radius: base_variables.$igBorderRadiusL;

    .p-accordion.invisible-accordion & {
      background: transparent;
    }

    &:not(.p-disabled) {
      &:hover,
      &:focus-within,
      &.p-accordion-tab-active {
        background: panel.$accordionHeaderHoverBg;
        @include mix.box-shadow($color: rgba(51, 51, 51, 0.12));

        .p-accordion.invisible-accordion & {
          background: transparent;
          box-shadow: none;
        }
      }
    }
    .p-accordion-header {
      border: none;
      background: transparent;
      transition: none;
      & > .p-accordion-header-link {
        border: none;
        background: transparent!important;
        color: inherit!important;
        position: relative;
        text-decoration: none;
        outline: none;
        box-shadow: none;
        &:focus {
          box-shadow: none!important;
        }

        // Change Icon Order
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;

        .p-accordion-header-text {
          font-size: panel.$accordionHeaderFontSize;
          font-weight: panel.$accordionHeaderFontWeight;
          color: panel.$accordionHeaderTextColor;
        }
      }
    }

    .p-accordion.invisible-accordion & {
      .p-accordion-header, .p-accordion-content, .p-accordion-header-link {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
      }
    }
  }
}
