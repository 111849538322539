@use "../../mixins" as mixins;
@use "../../../variables/panel" as panel;
@use "../../../variables/data" as data;
@use "../../../variables/general" as general;
@use "../../../variables/form" as form;
.p-organizationchart {
    .p-organizationchart-node-content {
        &.p-organizationchart-selectable-node:not(.p-highlight):hover,
        &.p-organizationchart-selectable-node:not(.p-highlight):focus-within {
            background: form.$inputListItemHoverBg;
            color: form.$inputListItemTextHoverColor;
        }

        &.p-highlight {
            background: general.$highlightBg;
            color: general.$highlightTextColor;

            .p-node-toggler {
                i {
                    color: var(--primary-color-darker20);
                }
            }
        }
    }

    .p-organizationchart-line-down {
        background: data.$organizationChartConnectorColor;
    }

    .p-organizationchart-line-left {
        border-right: panel.$panelContentBorder;
        border-color: data.$organizationChartConnectorColor;
    }

    .p-organizationchart-line-top {
        border-top: panel.$panelContentBorder;
        border-color: data.$organizationChartConnectorColor;
    }

    .p-organizationchart-node-content {
        border: panel.$panelContentBorder;
        background: panel.$panelContentBg;
        color: panel.$panelContentTextColor;
        padding: panel.$panelContentPadding;
    }

    .p-organizationchart-node-content .p-node-toggler {
        background: inherit;
        color: inherit;
        border-radius: 50%;

        &:focus {
            @include mixins.focused();
        }
    }
}
