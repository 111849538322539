@use "../../../variables/media" as media;
@use "../../../variables/general" as general;
@use "../../mixins" as mixins;
.p-carousel {
    .p-carousel-content {
        .p-carousel-prev,
        .p-carousel-next {
            @include mixins.action-icon();
            margin: general.$inlineSpacing;
        }
    }

    .p-carousel-indicators {
        padding: media.$carouselIndicatorsPadding;

        .p-carousel-indicator {
            margin-right: general.$inlineSpacing;
            margin-bottom: general.$inlineSpacing;

            button {
                background-color: media.$carouselIndicatorBg;
                width: media.$carouselIndicatorWidth;
                height: media.$carouselIndicatorHeight;
                transition: general.$actionIconTransition;
                border-radius: media.$carouselIndicatorBorderRadius;
                border: media.$carouselIndicatorBorder;

                &:hover, &:focus-within {
                    background: media.$carouselIndicatorHoverBg;
                }
            }

            &.p-highlight {
                button {
                    background: general.$highlightBg;
                    color: general.$highlightTextColor;
                }
            }
        }
    }
}
