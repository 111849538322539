@use "../../../variables/button" as button;
@use "../../../variables/general" as general;
@use "../../../variables/panel" as panel;
@use "../../../variables/overlay" as overlay;
@use "sass:math";

.p-overlaypanel {
    background: overlay.$overlayContentBg;
    color: panel.$panelContentTextColor;
    border: overlay.$overlayContentBorder;
    border-radius: general.$borderRadius;
    box-shadow: overlay.$overlayContainerShadow;

    .p-overlaypanel-content {
        padding: panel.$panelContentPadding;
    }

    .p-overlaypanel-close {
        background: button.$buttonBg;
        color: button.$buttonTextColor;
        width: general.$actionIconWidth;
        height: general.$actionIconHeight;
        transition: general.$actionIconTransition;
        border-radius: general.$actionIconBorderRadius;
        position: absolute;
        top: -1 * math.div(general.$actionIconWidth, 2);
        right: -1 * math.div(general.$actionIconWidth, 2);

        &:enabled {
          &:hover, &:focus-within {
            background: button.$buttonHoverBg;
            color: button.$buttonTextHoverColor;
          }
        }
    }

    &:after {
        border: solid transparent;
        border-color: rgba(255,255,255, 0);
        border-bottom-color: overlay.$overlayContentBg;
    }

    &:before {
        border: solid transparent;

        @if (nth(overlay.$overlayContentBorder, 2) == 'none') {
            border-color: rgba(255,255,255, 0);
            border-bottom-color: overlay.$overlayContentBg;
        }
        @else {
            border-color: rgba(255,255,255, 0);
            border-bottom-color: nth(overlay.$overlayContentBorder, 3);
        }
    }

    &.p-overlaypanel-flipped {
        &:after {
            border-top-color: overlay.$overlayContentBg;
        }

        &:before {
            @if (nth(overlay.$overlayContentBorder, 2) == 'none') {
                border-top-color: overlay.$overlayContentBg;
            }
            @else {
                border-top-color: nth(overlay.$overlayContentBorder, 3);
            }
        }
    }
}
