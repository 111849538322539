@use "../../../variables/data" as data;
@use "../../../variables/menu" as menu;
@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/panel" as panel;
.p-panelmenu {
    .p-panelmenu-header {
        > a {
            padding: panel.$accordionHeaderPadding;
            border: panel.$accordionHeaderBorder;
            color: panel.$accordionHeaderTextColor;
            background: panel.$accordionHeaderBg;
            font-weight: panel.$accordionHeaderFontWeight;
            border-radius: general.$borderRadius;
            transition: general.$listItemTransition;

            .p-panelmenu-icon {
                margin-right: general.$inlineSpacing;
            }

            .p-menuitem-icon {
                margin-right: general.$inlineSpacing;
            }

            &:focus {
                @include mixins.focused();
            }
        }

        &:not(.p-highlight):not(.p-disabled) {
            > a:hover, > a:focus-within {
                background: panel.$accordionHeaderHoverBg;
                border-color: panel.$accordionHeaderHoverBorderColor;
                color: panel.$accordionHeaderTextHoverColor;
            }
        }

        &.p-highlight {
            margin-bottom: 0;

            > a {
                background: panel.$accordionHeaderActiveBg;
                border-color: panel.$accordionHeaderActiveBorderColor;
                color: panel.$accordionHeaderTextActiveColor;
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
            }

            &:not(.p-disabled) {
                > a {
                    &:hover, &:focus-within {
                        border-color: panel.$accordionHeaderActiveHoverBorderColor;
                        background: panel.$accordionHeaderActiveHoverBg;
                        color: panel.$accordionHeaderTextActiveHoverColor;
                    }
                }
            }
        }
    }

    .p-panelmenu-content {
        padding: menu.$verticalMenuPadding;
        border: panel.$accordionContentBorder;
        background: panel.$accordionContentBg;
        color: panel.$accordionContentTextColor;
        margin-bottom: panel.$accordionSpacing;
        border-top: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        border-bottom-right-radius: general.$borderRadius;
        border-bottom-left-radius: general.$borderRadius;

        .p-menuitem {
            .p-menuitem-link {
                @include mixins.menuitem-link();

                .p-panelmenu-icon {
                    margin-right: general.$inlineSpacing;
                }
            }
        }

        .p-submenu-list:not(.p-panelmenu-root-submenu) {
            padding: data.$treeNodeChildrenPadding;
        }
    }

    .p-panelmenu-panel {
        margin-bottom: panel.$accordionSpacing;

        @if panel.$accordionSpacing == 0 {
            .p-panelmenu-header {
                > a {
                    border-radius: 0;
                }
            }

            .p-panelmenu-content {
                border-radius: 0;
            }

            &:not(:first-child) {
                .p-panelmenu-header {
                    > a {
                        border-top: 0 none;
                    }

                    &:not(.p-highlight):not(.p-disabled):hover,
                    &:not(.p-highlight):not(.p-disabled):focus-within,
                    &:not(.p-disabled).p-highlight:hover,
                    &:not(.p-disabled).p-highlight:focus-within {
                        > a {
                            border-top: 0 none;
                        }
                    }
                }
            }

            &:first-child {
                .p-panelmenu-header {
                    > a {
                        border-top-right-radius: general.$borderRadius;
                        border-top-left-radius: general.$borderRadius;
                    }
                }
            }

            &:last-child {
                .p-panelmenu-header:not(.p-highlight) {
                    > a {
                        border-bottom-right-radius: general.$borderRadius;
                        border-bottom-left-radius: general.$borderRadius;
                    }
                }

                .p-panelmenu-content {
                    border-bottom-right-radius: general.$borderRadius;
                    border-bottom-left-radius: general.$borderRadius;
                }
            }
        }
    }

}
