@use "../../../variables/general" as general;
@use "../../mixins" as mixins;
@use "../../../variables/form" as form;
@use "sass:math";

.p-chips {
    .p-chips-multiple-container {
        padding: math.div(nth(form.$inputPadding, 1), 2) nth(form.$inputPadding, 2);

        &:not(.p-disabled):hover,
        &:not(.p-disabled):focus-within {
            border-color: form.$inputHoverBorderColor;
        }

        &:not(.p-disabled).p-focus {
            @include mixins.focused-input();
        }

        .p-chips-token {
            padding: math.div(nth(form.$inputPadding, 1), 2) nth(form.$inputPadding, 2);
            margin-right: general.$inlineSpacing;
            background: general.$highlightBg;
            color: general.$highlightTextColor;
            border-radius: general.$borderRadius;

            .p-chips-token-icon {
                margin-left: general.$inlineSpacing;
            }
        }

        .p-chips-input-token {
            padding: math.div(nth(form.$inputPadding, 1), 2) 0;

            input {
                font-family: general.$fontFamily;
                font-size: general.$fontSize;
                color: var(--ig-text-color);
                padding: 0;
                margin: 0;
            }
        }
    }

    &.p-error > .p-inputtext,
    &.p-invalid > .p-inputtext {
        @include mixins.invalid-input();
    }
}

p-chips.ng-dirty.ng-invalid > .p-chips > .p-inputtext {
    @include mixins.invalid-input();
}
