@use "../../../variables/general" as general;
@use "../../mixins" as mixins;
@use "../../../variables/panel" as panel;
@use "../../../variables/overlay" as overlay;
.p-sidebar {
    background: overlay.$overlayContentBg;
    color: panel.$panelContentTextColor;
    padding: panel.$panelContentPadding;
    border: overlay.$overlayContentBorder;
    box-shadow: overlay.$overlayContainerShadow;

    .p-sidebar-close {
        @include mixins.action-icon();
    }
}

.p-sidebar-mask.p-component-overlay {
    background: general.$maskBg;
}
