@use "../../../variables/data" as data;
@use "../../mixins" as mixins;
@use "../../../variables/form" as form;
@use "../../../variables/general" as general;
@use "../../../variables/panel" as panel;
.p-orderlist {
    .p-orderlist-controls {
        padding: panel.$panelContentPadding;

        .p-button {
            margin-bottom: general.$inlineSpacing;
        }
    }

    .p-orderlist-header {
        background: panel.$panelHeaderBg;
        color: panel.$panelHeaderTextColor;
        border: panel.$panelHeaderBorder;
        padding: panel.$panelHeaderPadding;
        border-bottom: 0 none;
        border-top-right-radius: general.$borderRadius;
        border-top-left-radius: general.$borderRadius;

        .p-orderlist-title {
            font-weight: panel.$panelHeaderFontWeight;
        }
    }

    .p-orderlist-filter-container {
        padding: panel.$panelHeaderPadding;
        background: panel.$panelContentBg;
        border: panel.$panelHeaderBorder;
        border-bottom: 0 none;

        .p-orderlist-filter-input {
            padding-right: nth(form.$inputPadding, 2) + general.$primeIconFontSize;
        }

        .p-orderlist-filter-icon {
            right: nth(form.$inputPadding, 2);
            color: form.$inputIconColor;
        }
    }

    .p-orderlist-list {
        border: panel.$panelContentBorder;
        background: panel.$panelContentBg;
        color: panel.$panelContentTextColor;
        padding: form.$inputListPadding;
        border-bottom-right-radius: general.$borderRadius;
        border-bottom-left-radius: general.$borderRadius;

        .p-orderlist-item {
            padding: form.$inputListItemPadding;
            margin: form.$inputListItemMargin;
            border: form.$inputListItemBorder;
            color: form.$inputListItemTextColor;
            background: form.$inputListItemBg;
            transition: transform general.$transitionDuration, general.$listItemTransition;

            &:not(.p-highlight):hover,
            &:not(.p-highlight):focus-within {
                background: form.$inputListItemHoverBg;
                color: form.$inputListItemTextHoverColor;
            }

            &:focus {
                @include mixins.focused-listitem();
            }

            &.p-highlight {
                color: general.$highlightTextColor;
                background: general.$highlightBg;
            }
        }

        .p-orderlist-droppoint {
            &.p-orderlist-droppoint-highlight {
                background-color: var(--primary-color-darker20);
            }
        }
    }
}

@media screen and (max-width: data.$orderListBreakpoint) {
    .p-orderlist  {
        flex-direction: column;

        .p-orderlist-controls {
            padding: panel.$panelContentPadding;
            flex-direction: row;

            .p-button {
                margin-right: general.$inlineSpacing;
                margin-bottom: 0;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}
