@use "sass:meta";
/* Customizations to the designer theme should be defined here */

@include meta.load-css('./extensions/button'); // custom rules for primeNG buttons
@include meta.load-css('./extensions/card'); // custom rules for primeNG cards
@include meta.load-css('./extensions/color'); // custom rules for primeNG cards
@include meta.load-css('./extensions/dialog'); // custom rules for primeNG dialogs
@include meta.load-css('./extensions/form'); // custom rules for primeNG form
@include meta.load-css('./extensions/grid'); // custom rules for primeNG grid
@include meta.load-css('./extensions/menu'); // custom rules for primeNG menu
@include meta.load-css('./extensions/overlaypanel'); // custom rules for primeNG overlay panel
//@include meta.load-css('./extensions/table'); // custom rules for primeNG tables / gs-table
@include meta.load-css('./extensions/toast'); // custom rules for primeNG toast
@include meta.load-css('./extensions/inputtext'); // custom rules for primeNG toast
@include meta.load-css('./extensions/accordion'); // custom rules for primeNG accoredeon
@include meta.load-css('./extensions/misc'); // custom rules for primeNG misc

@include meta.load-css('./extensions/inputswitch'); // custom rules for primeNG toast
@include meta.load-css('./extensions/inputnumber'); // custom rules for primeNG number input
