@use "../../../variables/general" as general;
@use "../../../variables/form" as form;
.p-editor-container {
    .p-editor-toolbar {
        background: form.$editorToolbarBg;
        border-top-right-radius: general.$borderRadius;
        border-top-left-radius: general.$borderRadius;

        &.ql-snow {
            border: form.$editorToolbarBorder;

            .ql-stroke {
                stroke: form.$editorToolbarIconColor;
            }

            .ql-fill {
                fill: form.$editorToolbarIconColor;
            }

            .ql-picker {
                .ql-picker-label {
                    border: 0 none;
                    color: form.$editorToolbarIconColor;

                    &:hover, &:focus-within {
                        color: form.$editorToolbarIconHoverColor;

                        .ql-stroke {
                            stroke: form.$editorToolbarIconHoverColor;
                        }

                        .ql-fill {
                            fill: form.$editorToolbarIconHoverColor;
                        }
                    }
                }

                &.ql-expanded {
                    .ql-picker-label {
                        color: form.$editorToolbarIconHoverColor;

                        .ql-stroke {
                            stroke: form.$editorToolbarIconHoverColor;
                        }

                        .ql-fill {
                            fill: form.$editorToolbarIconHoverColor;
                        }
                    }

                    .ql-picker-options {
                        background: form.$inputOverlayBg;
                        border:form.$inputOverlayBorder;
                        box-shadow:form.$inputOverlayShadow;
                        border-radius: general.$borderRadius;
                        padding: form.$inputListPadding;

                        .ql-picker-item {
                            color: form.$inputListItemTextColor;

                            &:hover, &:focus-within {
                                color: form.$inputListItemTextHoverColor;
                                background: form.$inputListItemHoverBg;
                            }
                        }
                    }

                    &:not(.ql-icon-picker) {
                        .ql-picker-item {
                            padding: form.$inputListItemPadding;
                        }
                    }
                }
            }
        }
    }

    .p-editor-content {
        border-bottom-right-radius: general.$borderRadius;
        border-bottom-left-radius: general.$borderRadius;

        &.ql-snow {
            border: form.$editorContentBorder;
        }

        .ql-editor {
            background: form.$inputBg;
            color: form.$inputTextColor;
            border-bottom-right-radius: general.$borderRadius;
            border-bottom-left-radius: general.$borderRadius;
        }
    }

    .ql-snow.ql-toolbar button:hover,
    .ql-snow.ql-toolbar button:focus-within,
    .ql-snow.ql-toolbar button:focus {
        color: form.$editorToolbarIconHoverColor;

        .ql-stroke {
            stroke: form.$editorToolbarIconHoverColor;
        }

        .ql-fill {
            fill: form.$editorToolbarIconHoverColor;
        }
    }

    .ql-snow.ql-toolbar button.ql-active,
    .ql-snow.ql-toolbar .ql-picker-label.ql-active,
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected {
        color: form.$editorIconActiveColor;

        .ql-stroke {
            stroke: form.$editorIconActiveColor;
        }

        .ql-fill {
            fill: form.$editorIconActiveColor;
        }

        .ql-picker-label {
            color: form.$editorIconActiveColor;
        }
    }
}
