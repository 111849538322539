@use "../../mixins" as mixins;
@use "../../../variables/form" as form;
@use "../../../variables/general" as general;
.p-inputtext {
    font-family: general.$fontFamily;
    font-size: form.$inputTextFontSize;
    color: form.$inputTextColor;
    background: form.$inputBg;
    padding: form.$inputPadding;
    border: form.$inputBorder;
    transition: general.$formElementTransition;
    appearance: none;
    border-radius: general.$borderRadius;

    &:enabled {
      &:hover, &:focus-within {
        border-color: form.$inputHoverBorderColor;
      }
    }

    &:enabled:focus {
        @include mixins.focused-input();
    }

    &.p-error,
    &.p-invalid,
    &.ng-dirty.ng-invalid.ng-touched {
        @include mixins.invalid-input();
    }

    &.p-inputtext-sm {
        @include mixins.scaledFontSize(form.$inputTextFontSize, general.$scaleSM);
        @include mixins.scaledPadding(form.$inputPadding, general.$scaleSM);
    }

    &.p-inputtext-lg {
        @include mixins.scaledFontSize(form.$inputTextFontSize, general.$scaleLG);
        @include mixins.scaledPadding(form.$inputPadding, general.$scaleLG);
    }
}

.p-float-label > label {
    left: nth(form.$inputPadding, 2);
    color: form.$inputPlaceholderTextColor;
    transition-duration: general.$transitionDuration;
}

.p-float-label > .ng-invalid.ng-dirty.ng-touched + label {
    color: form.$inputErrorBorderColor;
}

.p-input-icon-left > i:first-of-type {
    left: nth(form.$inputPadding, 2);
    color: form.$inputIconColor;
}

.p-input-icon-left > .p-inputtext {
    padding-left: nth(form.$inputPadding, 2) * 2 + general.$primeIconFontSize;
}

.p-input-icon-left.p-float-label > label {
    left: nth(form.$inputPadding, 2) * 2 + general.$primeIconFontSize;
}

.p-input-icon-right > i:last-of-type  {
    right: nth(form.$inputPadding, 2);
    color: form.$inputIconColor;
}

.p-input-icon-right > .p-inputtext {
    padding-right: nth(form.$inputPadding, 2) * 2 + general.$primeIconFontSize;
}

@include mixins.placeholder {
    color: form.$inputPlaceholderTextColor
};

.p-input-filled {
    .p-inputtext {
        background-color: form.$inputFilledBg;

        &:enabled {
          &:hover, &:focus-within {
            background-color: form.$inputFilledHoverBg;
          }
        }

        &:enabled:focus {
            background-color: form.$inputFilledFocusBg;
        }
    }
}

.p-inputtext-sm {
    .p-inputtext {
        @include mixins.scaledFontSize(form.$inputTextFontSize, general.$scaleSM);
        @include mixins.scaledPadding(form.$inputPadding, general.$scaleSM);
    }
}

.p-inputtext-lg {
    .p-inputtext {
        @include mixins.scaledFontSize(form.$inputTextFontSize, general.$scaleLG);
        @include mixins.scaledPadding(form.$inputPadding, general.$scaleLG);
    }
}
