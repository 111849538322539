@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/menu" as menu;
.p-megamenu {
    padding: menu.$horizontalMenuPadding;
    background: menu.$horizontalMenuBg;
    color: menu.$horizontalMenuTextColor;
    border: menu.$horizontalMenuBorder;
    border-radius: general.$borderRadius;

    .p-megamenu-root-list {
        > .p-menuitem {
            > .p-menuitem-link {
                @include mixins.horizontal-rootmenuitem-link();
            }

            &.p-menuitem-active {
                > .p-menuitem-link,
                > .p-menuitem-link:not(.p-disabled) {
                  &:hover, &:focus-within {
                    background: menu.$horizontalMenuRootMenuitemActiveBg;

                    .p-menuitem-text {
                        color: menu.$horizontalMenuRootMenuitemTextActiveColor;
                    }

                    .p-menuitem-icon {
                        color: menu.$horizontalMenuRootMenuitemIconActiveColor;
                    }

                    .p-submenu-icon {
                        color: menu.$horizontalMenuRootMenuitemIconActiveColor;
                    }
                  }
                }
            }
        }
    }

    .p-menuitem-link {
        @include mixins.menuitem-link();
    }

    .p-megamenu-panel {
        background: menu.$overlayMenuBg;
        color: menu.$menuTextColor;
        border: menu.$overlayMenuBorder;
        box-shadow: menu.$overlayMenuShadow;
    }

    .p-megamenu-submenu-header {
        margin: menu.$submenuHeaderMargin;
        padding: menu.$submenuHeaderPadding;
        color: menu.$submenuHeaderTextColor;
        background: menu.$submenuHeaderBg;
        font-weight: menu.$submenuHeaderFontWeight;
        border-top-right-radius: general.$borderRadius;
        border-top-left-radius: general.$borderRadius;
    }

    .p-megamenu-submenu {
        padding: menu.$verticalMenuPadding;
        width: menu.$menuWidth;

        .p-menu-separator {
            border-top: general.$divider;
            margin: menu.$menuSeparatorMargin;
        }

        .p-menuitem {
            margin: menu.$verticalMenuitemMargin;

            &:last-child {
                margin: 0;
            }
        }
    }

    .p-menuitem {
        &.p-menuitem-active {
            > .p-menuitem-link {
                background: menu.$menuitemActiveBg;

                .p-menuitem-text {
                    color: menu.$menuitemTextActiveColor;
                }

                .p-menuitem-icon, .p-submenu-icon {
                    color: menu.$menuitemIconActiveColor;
                }
            }
        }
    }

    &.p-megamenu-vertical {
        width: menu.$menuWidth;
        padding: menu.$verticalMenuPadding;

        .p-menuitem {
            margin: menu.$verticalMenuitemMargin;

            &:last-child {
                margin: 0;
            }
        }
    }
}
