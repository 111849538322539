@use "general" as general;
@use "form" as form;
/// Margin of a message
/// @group message
$messageMargin: 1rem 0;

/// Padding of a message
/// @group message
$messagePadding: 1rem 1.5rem;

/// Border width of a message
/// @group message
$messageBorderWidth: 0 0 0 4px;

/// Font size of a message icon
/// @group message
$messageIconFontSize: 1.5rem;

/// Font size of a message text
/// @group message
$messageTextFontSize: 1rem;

/// Font weight of a message text
/// @group message
$messageTextFontWeight: 500;

/// Padding of an inline message
/// @group message
$inlineMessagePadding: form.$inputPadding;

/// Margin of an inline message
/// @group message
$inlineMessageMargin: 0;

/// Font size of an inline message icon
/// @group message
$inlineMessageIconFontSize: 1rem;

/// Padding of an inline message text
/// @group message
$inlineMessageTextFontSize: 1rem;

/// Border width of an inline message text
/// @group message
$inlineMessageBorderWidth: 1px;

/// Font size of a toast message icon
/// @group message
$toastIconFontSize: 2rem;

/// Margin of a toast message text
/// @group message
$toastMessageTextMargin: 0 0 0 1rem;

/// Margin of a toast message
/// @group message
$toastMargin: 0 0 1rem 0;

/// Padding of a toast message
/// @group message
$toastPadding: 1rem;

/// Border width of a toast message
/// @group message
$toastBorderWidth: 0 0 0 4px;

/// Box shadow of a toast message
/// @group message
$toastShadow: var(--box-shadow);

/// Opacity of a toast message
/// @group message
$toastOpacity: 1.0;

/// Font weight of a toast message title text
/// @group message
$toastTitleFontWeight: 700;

/// Margin of a toast message detail text
/// @group message
$toastDetailMargin: general.$inlineSpacing 0 0 0;

/// Background of an info message
/// @group message
$infoMessageBg: var(--ig-color-alert-info-background);

/// Border of an info message
/// @group message
$infoMessageBorder: none;

/// Text color of an info message
/// @group message
$infoMessageTextColor: var(--ig-color-alert-info-foreground);

/// Icon color of an info message
/// @group message
$infoMessageIconColor: var(--ig-color-alert-info-foreground);

/// Background of a success message
/// @group message
$successMessageBg: var(--ig-color-alert-success-background);

/// Border of a success message
/// @group message
$successMessageBorder: 0 none;

/// Text color of a success message
/// @group message
$successMessageTextColor: var(--ig-color-alert-success-foreground);

/// Icon color of a success message
/// @group message
$successMessageIconColor: var(--ig-color-alert-success-foreground);

/// Background of a warning message
/// @group message
$warningMessageBg: var(--ig-color-alert-warning-background);

/// Border of a warning message
/// @group message
$warningMessageBorder: 0 none;

/// Text color of a warning message
/// @group message
$warningMessageTextColor: var(--ig-color-alert-warning-foreground);

/// Icon color of a warning message
/// @group message
$warningMessageIconColor: var(--ig-color-alert-warning-foreground);

/// Background of an error message
/// @group message
$errorMessageBg: var(--ig-color-alert-error-background);

/// Border of an error message
/// @group message
$errorMessageBorder: 0 none;

/// Text color of an error message
/// @group message
$errorMessageTextColor: var(--ig-color-alert-error-foreground);

/// Icon color of an error message
/// @group message
$errorMessageIconColor: var(--ig-color-alert-error-foreground);
