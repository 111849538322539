@use "general" as general;
/// Padding of an input field, must be defined with a shorthand for vertical and horizontal values e.g. ".5rem .5rem"
/// @group form
$inputPadding: 0.562rem 0.5rem;

$inputPaddingLg: 0.875rem 0.625rem;

/// Background of an input field
/// @group form
$inputBg: var(--ig-color-form-input-background);

/// Font size of an input field
/// @group form
$inputTextFontSize: 14px;

/// Text color of an input field
/// @group form
$inputTextColor: var(--ig-color-form-input-foreground);

/// Color of an icon inside an input field
/// @group form
$inputIconColor: var(--input-form-border);

/// Border of an input field
/// @group form
$inputBorder: 1px solid var(--input-form-border);

/// Border of an input field in hover state
/// @group form
$inputHoverBorderColor: var(--icons-color);

/// Border of an input field in focus state
/// @group form
$inputFocusBorderColor: var(--icons-color);

/// Color of an input field in invalid state
/// @group form
$inputErrorBorderColor: general.$errorColor;

/// Text color of a placeholder
/// @group form
$inputPlaceholderTextColor: var(--ig-color-form-input-placeholder-foreground);

/// Background of a filled input
/// @group form
$inputFilledBg: var(--ig-color-form-clickarea-background-highlight);

/// Background of a filled input in hover state
/// @group form
$inputFilledHoverBg: var(--ig-color-form-clickarea-background-highlight);

/// Background of a filled input in focus state
/// @group form
$inputFilledFocusBg: var(--ig-color-form-clickarea-background-highlight);

/// Backgroud color of an input group addon
/// @group form
$inputGroupBg: var(--ig-color-card-background);

/// Text color of an input group addon
/// @group form
$inputGroupTextColor: var(--ig-color-card-label);

/// Minimum width of an input group addon
/// @group form
$inputGroupAddOnMinWidth: 2.357rem;

/// Background of an input list such as dropdown, listbox, multiselect
/// @group form
$inputListBg: var(--ig-color-menu-background);

/// Text color of an input list
/// @group form
$inputListTextColor: var(--ig-color-menu-foreground-light);

/// Border of an input list
/// @group form
$inputListBorder: $inputBorder;

/// Padding of an input list
/// @group form
$inputListPadding: 0.5rem 0;

/// Padding for an individual itrem of an input list
/// @group form
$inputListItemPadding: 0.357rem 1rem;

/// Background for an individual itrem of an input list
/// @group form
$inputListItemBg: transparent;

/// Text color for an individual itrem of an input list
/// @group form
$inputListItemTextColor: var(--ig-color-menu-foreground);

/// Hover state background for an individual itrem of an input list
/// @group form
$inputListItemHoverBg: var(--ig-color-menu-background-hover);

/// Hover state text color for an individual itrem of an input list
/// @group form
$inputListItemTextHoverColor: var(--ig-color-menu-foreground);

/// Border for an individual itrem of an input list
/// @group form
$inputListItemBorder: 0 none;

/// Border radius for an individual itrem of an input list
/// @group form
$inputListItemBorderRadius: 0;

/// Margin for an individual itrem of an input list
/// @group form
$inputListItemMargin: 0;

/// Box shadow for an individual itrem of an input list in focused state
/// @group form
$inputListItemFocusShadow: inset 0 0 0 0.15rem general.$focusOutlineColor;

/// Padding for a header of an input list
/// @group form
$inputListHeaderPadding: 0.357rem 1rem;

/// Margin for a header of an input list
/// @group form
$inputListHeaderMargin: 0;

/// Background for a header of an input list
/// @group form
$inputListHeaderBg: var(--ig-color-white);

/// Text color for a header of an input list
/// @group form
$inputListHeaderTextColor: var(--text-color);

/// Border for a header of an input list
/// @group form
$inputListHeaderBorder: 0 none;

/// Background for an overlay of an input such as autocomplete or dropdown
/// @group form
$inputOverlayBg: $inputListBg;

/// Background for an overlay header of an input such as autocomplete or dropdown
/// @group form
$inputOverlayHeaderBg: $inputListHeaderBg;

/// Border for an overlay of an input such as autocomplete or dropdown
/// @group form
$inputOverlayBorder: var(--high-contrast-border);

/// Shadow for an overlay of an input such as autocomplete or dropdown
/// @group form
$inputOverlayShadow: var(--ig-box-shadow);

/// Width of a checkbox
/// @group form
$checkboxWidth: 20px;

/// Height of a checkbox
/// @group form
$checkboxHeight: 20px;

/// Border of a checkbox
/// @group form
$checkboxBorder: 1px solid var(--input-checkbox-border);

/// Size of a checkbox icon
/// @group form
$checkboxIconFontSize: 12px;

/// Border color of a selected checkbox
/// @group form
$checkboxActiveBorderColor: var(--icons-color);

/// Background of a selected checkbox
/// @group form
$checkboxActiveBg: transparent;

/// Icon color of a selected checkbox
/// @group form
$checkboxIconActiveColor: var(--icons-color);

/// Background of a selected checkbox in hover state
/// @group form
$checkboxActiveHoverBg: transparent;

/// Icon color of a selected checkbox in hover state
/// @group form
$checkboxIconActiveHoverColor: var(--icons-color);

/// Border color of a selected checkbox in hover state
/// @group form
$checkboxActiveHoverBorderColor: var(--icons-color);

/// Width of a radiobutton
/// @group form
$radiobuttonWidth: 20px;

/// Height of a radiobutton
/// @group form
$radiobuttonHeight: 20px;

/// Border of a radiobutton
/// @group form
$radiobuttonBorder: 1px solid var(--input-form-border);

/// Font size of a radiobutton icon
/// @group form
$radiobuttonIconSize: 10px;

/// Border color of a selected radiobutton
/// @group form
$radiobuttonActiveBorderColor: var(--input-form-border);

/// Background of a selected radiobutton
/// @group form
$radiobuttonActiveBg: var(--primary-text-color);

/// Icon color of a selected radiobutton
/// @group form
$radiobuttonIconActiveColor: var(--icons-color);

/// Background of a selected radiobutton in hover state
/// @group form
$radiobuttonActiveHoverBg: var(--icons-darker-color);

/// Icon color of a selected radiobutton in hover state
/// @group form
$radiobuttonIconActiveHoverColor: var(--primary-text-color);

/// Border color of a selected radiobutton in hover state
/// @group form
$radiobuttonActiveHoverBorderColor: var(--icons-darker-color);

/// Width of a color picker preview element
/// @group form
$colorPickerPreviewWidth: 2rem;

/// Height of a color picker preview element
/// @group form
$colorPickerPreviewHeight: 2rem;

/// Background of a color picker
/// @group form
$colorPickerBg: var(--ig-color-black);

/// Border color of a color picker
/// @group form
$colorPickerBorderColor: var(--ig-color-card-border);

/// Handle color of a color picker
/// @group form
$colorPickerHandleColor: var(--ig-color-white);

/// Font size of a rating icon
/// @group form
$ratingIconFontSize: 1.143rem;

/// Icon color for the cancel icon of a rating
/// @group form
$ratingCancelIconColor: var(--ig-color-warning-red);

/// Hover icon color for the cancel icon of a rating
/// @group form
$ratingCancelIconHoverColor: var(--ig-color-warning-red);

/// Icon color for the star icon of a rating in unselected state
/// @group form
$ratingStarIconOffColor: var(--text-color);

/// Icon color for the star icon of a rating in selected state
/// @group form
$ratingStarIconOnColor: var(--icons-color);

/// Icon color for the star icon of a rating in hover state
/// @group form
$ratingStarIconHoverColor: var(--icons-color);

/// Background of a slider
/// @group form
$sliderBg: var(--ig-color-form-clickarea-background);

/// Border of a slider
/// @group form
$sliderBorder: var(--high-contrast-border);

/// Height of a horizontal slider
/// @group form
$sliderHorizontalHeight: 0.286rem;

/// Width of a vertical slider
/// @group form
$sliderVerticalWidth: 0.286rem;

/// Width of a slider handle
/// @group form
$sliderHandleWidth: 1.143rem;

/// Height of a slider handle
/// @group form
$sliderHandleHeight: 1.143rem;

/// Background of a slider handle
/// @group form
$sliderHandleBg: var(--secondary-color);

/// Border of a slider handle
/// @group form
$sliderHandleBorder: var(--high-contrast-border);

/// Border radius of a slider handle
/// @group form
$sliderHandleBorderRadius: 50%;

/// Border of a slider handle in hover state
/// @group form
$sliderHandleHoverBorderColor: var(--icons-color);

/// Background of a slider handle in hover state
/// @group form
$sliderHandleHoverBg: var(--icons-color);

/// Background color of a range slider
/// @group form
$sliderRangeBg: var(--icons-color);

/// Margin of a calendar table
/// @group form
$calendarTableMargin: 0.5rem 0;

/// Margin of a calendar
/// @group form
$calendarPadding: 0.5rem;

/// Background of a calendar
/// @group form
$calendarBg: var(--ig-color-menu-background);

/// Background of an inlime calendar
/// @group form
$calendarInlineBg: $calendarBg;

/// Text color of a calendar
/// @group form
$calendarTextColor: var(--ig-color-menu-foreground);

/// Border of an inline calendar
/// @group form
$calendarBorder: $inputListBorder;

/// Border of an overlay calendar
/// @group form
$calendarOverlayBorder: $inputOverlayBorder;

/// Padding of a calendar header
/// @group form
$calendarHeaderPadding: 0.5rem;

/// Background of a calendar header
/// @group form
$calendarHeaderBg: var(--ig-color-menu-background-hover);

/// Background of an inline calendar header
/// @group form
$calendarInlineHeaderBg: $calendarBg;

/// Border of a calendar header
/// @group form
$calendarHeaderBorder: none;

/// Text color of a calendar header
/// @group form
$calendarHeaderTextColor: var(--ig-color-menu-foreground);

/// Font weight of a calendar header
/// @group form
$calendarHeaderFontWeight: 600;

/// Padding of a calendar weekday cell
/// @group form
$calendarHeaderCellPadding: 0;

/// Padding of a calendar date cell
/// @group form
$calendarCellDatePadding: 0;

/// Width of a calendar date cell
/// @group form
$calendarCellDateWidth: 2.5rem;

/// Height of a calendar date cell
/// @group form
$calendarCellDateHeight: 2.5rem;

/// Border radius of a calendar date cell
/// @group form
$calendarCellDateBorderRadius: 50%;

/// Border of a calendar date cell
/// @group form
$calendarCellDateBorder: 1px solid transparent;

/// Background of a calendar date cell in hover state
/// @group form
$calendarCellDateHoverBg: var(--ig-color-form-clickarea-background-hover);

/// Background of a calendar date cell indicating today
/// @group form
$calendarCellDateTodayBg: var(--ig-color-form-clickarea-background-highlight);

/// Border color of a calendar date cell indicating today
/// @group form
$calendarCellDateTodayBorderColor: transparent;

/// Text color of a calendar date cell indicating today
/// @group form
$calendarCellDateTodayTextColor: var(--ig-color-form-clickarea-foreground-bold-highlight);

/// Padding of the calendar button bar
/// @group form
$calendarButtonBarPadding: 1rem 0;

/// Padding of a time picker container of a calendar
/// @group form
$calendarTimePickerPadding: 0.5rem;

/// Padding of a time picker element of a calendar
/// @group form
$calendarTimePickerElementPadding: 0 0.429rem;

/// Font size of a time picker element of a calendar
/// @group form
$calendarTimePickerTimeFontSize: 1.286rem;

/// Breakpoint of calendar to apply styles for small screens like phones
/// @group form
$calendarBreakpoint: 769px;

/// Padding of a calendar date cell on small screens like phones
/// @group form
$calendarCellDatePaddingSM: 0;

/// Width of an inputswitch
/// @group form
$inputSwitchWidth: 50px;

/// Height of an inputswitch
/// @group form
$inputSwitchHeight: 28px;

/// Border radius of an inputswitch
/// @group form
$inputSwitchBorderRadius: 30px;

/// Width of an inputswitch handle
/// @group form
$inputSwitchHandleWidth: 24px;

/// Height of an inputswitch handle
/// @group form
$inputSwitchHandleHeight: 24px;

/// Border radius of an inputswitch handle
/// @group form
$inputSwitchHandleBorderRadius: 50%;

/// Padding of an inputswitch slider
/// @group form
$inputSwitchSliderPadding: 0px;

/// Background color of an inputswitch slider when unselected
/// @group form
$inputSwitchSliderOffBg: var(--ig-color-form-input-switch-background-off);

/// Background color of an inputswitch handle when unselected
/// @group form
$inputSwitchHandleOffBg: var(--ig-color-form-input-switch-handle-off);

/// Hover background color of an inputswitch slider when unselected
/// @group form
$inputSwitchSliderOffHoverBg: var(--ig-color-form-clickarea-background-hover);

/// Background color of an inputswitch slider when selected
/// @group form
$inputSwitchSliderOnBg: var(--ig-color-form-input-switch-background-on);

/// Hover background color of an inputswitch slider when selected
/// @group form
$inputSwitchSliderOnHoverBg: var(--icons-darker-color);

/// Background color of an inputswitch handle when selected
/// @group form
$inputSwitchHandleOnBg: var(--ig-color-form-input-switch-handle-on);

/// Height for the progress bar of a fileupload
/// @group input
$fileUploadProgressBarHeight: 0.25rem;

/// Padding of the fileupload content section
/// @group input
$fileUploadContentPadding: 2rem 1rem;

/// Background of an editor toolbar
/// @group input
$editorToolbarBg: var(--ig-color-form-clickarea-background);

/// Border of an editor toolbar
/// @group input
$editorToolbarBorder: 1px solid var(--ig-color-card-border);

/// Padding of an editor toolbar
/// @group input
$editorToolbarPadding: 1rem;

/// Icon color of an editor toolbar
/// @group input
$editorToolbarIconColor: var(--icons-color);

/// Icon color of an editor toolbar in hover state
/// @group input
$editorToolbarIconHoverColor: var(--icons-color);

/// Icon color of an editor toolbar in active state
/// @group input
$editorIconActiveColor: var(--icons-color);

/// Border of an editor content
/// @group input
$editorContentBorder: 1px solid var(--ig-color-card-border);

/// Background of an editor content
/// @group input
$editorContentBg: var(--ig-color-white);
