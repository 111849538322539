@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/panel" as panel;
.p-tabmenu {
    .p-tabmenu-nav {
        background: panel.$tabviewNavBg;
        border: panel.$tabviewNavBorder;
        border-width: panel.$tabviewNavBorderWidth;

        .p-tabmenuitem {
            margin-right: panel.$tabviewHeaderSpacing;

            .p-menuitem-link {
                border: panel.$tabviewHeaderBorder;
                border-width: panel.$tabviewHeaderBorderWidth;
                border-color: panel.$tabviewHeaderBorderColor;
                background: panel.$tabviewHeaderBg;
                color: panel.$tabviewHeaderTextColor;
                padding: panel.$tabviewHeaderPadding;
                font-weight: panel.$tabviewHeaderFontWeight;
                border-top-right-radius: general.$borderRadius;
                border-top-left-radius: general.$borderRadius;
                transition: general.$listItemTransition;
                margin: panel.$tabviewHeaderMargin;

                .p-menuitem-icon {
                    margin-right: general.$inlineSpacing;
                }

                &:not(.p-disabled):focus {
                    @include mixins.focused();
                }
            }

            &:not(.p-highlight):not(.p-disabled):hover,
            &:not(.p-highlight):not(.p-disabled):focus-within {
                .p-menuitem-link {
                    background: panel.$tabviewHeaderHoverBg;
                    border-color: panel.$tabviewHeaderHoverBorderColor;
                    color: panel.$tabviewHeaderTextHoverColor;
                }
            }

            &.p-highlight {
                .p-menuitem-link {
                    background: panel.$tabviewHeaderActiveBg;
                    border-color: panel.$tabviewHeaderActiveBorderColor;
                    color: panel.$tabviewHeaderTextActiveColor;
                }
            }
        }
    }
}
