@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/form" as form;
@use "../../../variables/misc" as misc;
@use "sass:math";

.p-chip {
    background-color: misc.$chipBg;
    color: misc.$chipTextColor;
    border-radius: misc.$chipBorderRadius;
    padding: 0 nth(form.$inputPadding, 2);

    .p-chip-text {
        line-height: 1.5;
        margin-top: math.div(nth(form.$inputPadding, 1), 2);
        margin-bottom: math.div(nth(form.$inputPadding, 1), 2);
    }

    .p-chip-icon {
        margin-right: general.$inlineSpacing;
    }

    .pi-chip-remove-icon {
        margin-left: general.$inlineSpacing;
    }

    img {
        width: 1.5 + nth(form.$inputPadding, 1);
        height: 1.5 + nth(form.$inputPadding, 1);
        margin-left: -1 * nth(form.$inputPadding, 2);
        margin-right: general.$inlineSpacing;
    }

    .pi-chip-remove-icon {
        border-radius: general.$borderRadius;
        transition: general.$actionIconTransition;

        &:focus {
            @include mixins.focused();
        }
    }
}
