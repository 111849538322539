@use "../../../variables/general" as general;
@use "../../mixins" as mixins;
@use "../../../variables/menu" as menu;
.p-contextmenu {
    padding: menu.$verticalMenuPadding;
    background: menu.$overlayMenuBg;
    color: menu.$menuTextColor;
    border: menu.$overlayMenuBorder;
    box-shadow: menu.$overlayMenuShadow;
    width: menu.$menuWidth;

    .p-menuitem-link {
        @include mixins.menuitem-link();
    }

    .p-submenu-list {
        padding: menu.$verticalMenuPadding;
        background: menu.$overlayMenuBg;
        border: menu.$overlayMenuBorder;
        box-shadow: menu.$overlayMenuShadow;
    }

    .p-menuitem {
        margin: menu.$verticalMenuitemMargin;
    
        &:last-child {
            margin: 0;
        }

        &.p-menuitem-active {
            > .p-menuitem-link {
                background: menu.$menuitemActiveBg;

                .p-menuitem-text {
                    color: menu.$menuitemTextActiveColor;
                }

                .p-menuitem-icon, .p-submenu-icon {
                    color: menu.$menuitemIconActiveColor;
                }
            }
        }
    }

    .p-menu-separator {
        border-top: general.$divider;
        margin: menu.$menuSeparatorMargin;
    }

    .p-submenu-icon {
        font-size: menu.$menuitemSubmenuIconFontSize;
    }
}
