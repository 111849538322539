@use "../../foundations/typographie/typo.panel.vars" as typopanelvars;


.ig-tooltip {
  position: fixed;
  max-width: 320px !important;
  font-size: typopanelvars.$font-size-tooltip;
  line-height: typopanelvars.$line-height-tooltip;
  padding: 10px;
  background: var(--ig-color-tooltip-background);
  color: var(--ig-color-tooltip-foreground);
  border-radius: 3px;
  box-shadow: var(--box-shadow);
  // z-index: 4000; management by PrimeNG zindexUtils
  opacity: 0;

  a {
    color: inherit !important;
    text-decoration: underline;
  }

  &.ig-tooltip-visible {
    opacity: 1;
  }

  &:before {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    background: inherit;
    transform: rotate(45deg);
  }

  &.ig-tooltip-large {
    padding: 16px;
  }

  &.ig-tooltip-pos-top {
    &:before {
      left: 50%;
      margin-left: -5px;
      bottom: -5px;
      box-shadow: 3px 3px 6px -1px var(--ig-color-tooltip-shadow);
    }
  }
  &.ig-tooltip-pos-right {
    &:before {
      left: -5px;
      top: 50%;
      margin-top: -5px;
      box-shadow: -3px 3px 6px -1px var(--ig-color-tooltip-shadow);
    }
  }
  &.ig-tooltip-pos-left {
    &:before {
      right: -5px;
      top: 50%;
      margin-top: -5px;
      box-shadow: 3px -3px 6px -1px var(--ig-color-tooltip-shadow);
    }
  }
  &.ig-tooltip-pos-bottom {
    &:before {
      left: 50%;
      top: -5px;
      margin-left: -5px;
      box-shadow: -3px -3px 6px -1px var(--ig-color-tooltip-shadow);
    }
  }


  &>div {
    * {
      // some elements, like `strong` have another font size -.-
      font-size: inherit !important;
    }
  }

  &.ig-tooltip-constrain-width {
    &>div {
      word-wrap: break-word;
    }
  }

  &-mode {
    &-success {
      background-color: var(--ig-color-alert-success-background);
      color: var(--ig-color-alert-success-foreground);
    }
  }
}
