@use "../lib/foundations/typographie/typo.panel.vars" as typopanelvars;
@use "public/breakpoint" as breakpoint;
@use "sass:meta";
@use "sass:string";

@layer ingrid, primeng;


@include meta.load-css('./public/foundations_css');
@include meta.load-css('./public/elements_css');
@include meta.load-css('./public/components_css');
@include meta.load-css('./designer/components');
@include meta.load-css('extensions');
@include meta.load-css('./libs/favorite_switch'); // a favorite toggle (@TODO: should once get an own element)
@include meta.load-css('./libs/flex'); // flexbox lib with mixins covering all browsers
@include meta.load-css('./libs/input'); // custom input classes (@TODO: should once get an own element)
@include meta.load-css('./libs/primeflex'); // custom input classes (@TODO: should once get an own element)
@include meta.load-css('@gridscale/primeng17/resources/primeng');





@include breakpoint.response-classes();



:root {
  --surface-a: #ffffff;
  --surface-b: #f8f9fa;
  --surface-c: #e9ecef;
  --surface-d: #dee2e6;
  --surface-e: #ffffff;
  --surface-f: #ffffff;
  --text-color: var(--ig-text-color, #495057);
  --text-color-secondary: var(--text-secondary-color, #6c757d);
  --primary-color: #2196f3;
  --primary-color-text: #ffffff;
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

//@include meta.load-css('./libs/box'); // a box style with different states (@TODO:should once get an own element)
//@include meta.load-css('./libs/dialog'); // a dialog (@TODO: should once get an own element)


// import primeflex foo

// prism css for ig-code
@include meta.load-css('prismjs/themes/prism.css');

@layer ingrid {
  * {
    outline-style: none;
    margin: 0;
    padding: 0;
  }
}

html,
body {
  height: 100%;
}

html {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--skin-font-family);
  color: var(--ig-text-color);
  p {
    color: var(--ig-text-color);
  }

  &.darkmode {
    color-scheme: dark;
    ::selection {
      background: #eee;
    }
  }
  &.highcontrast {
    a:not(.linkinbutton) {
      // in high contrast mode use default link colors from browser
      color: revert!important;
    }


    // as there are no background colors in forced-colors mode, try to select all elements where one item can be active and use a bold font (product-selector, tabs, filter...)
    [class*="--active"], [class*="--is-active"], [class*="label-active"], [class*="filter-active"], [class*="isActive"], [aria-checked="true"] {
      font-weight: typopanelvars.$font-weight-bold !important;
      * {
        font-weight: typopanelvars.$font-weight-bold !important;
      }
    }
  }
}


/*
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--primary-color);
  font-weight: normal;
  font-family: var(--skin-font-family), sans-serif !important;
}
*/

label {
  cursor: pointer;
  color: var(--ig-color-label-light);
}

.ig-label-title {
  color: var(--ig-color-label);
}

small {
  color: var(--ig-color-label-light);
}

.relative {
  position: relative;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}


.full-width {
  width: 100%;
}

.no-scrolling {
  overflow: hidden;
}

.p-menuitem-content a {
  text-decoration: none;
}

h1 {
  color: var(--h1-color);
}
h2 {
  color: var(--h2-color);
}
h3 {
  color: var(--h3-color);
}
h4 {
  color: var(--h4-color);
}
h5 {
  color: var(--h5-color);
}
h6 {
  color: var(--h6-color);
}
.aria-visible-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  &:focus {
    clip: auto;
    height: auto;
    overflow: auto;
    position: absolute;
    width: auto;
  }
}
