@use "../../../scss/public/base_variables" as base_variables;
@use "../../foundations/typographie/typo.panel.vars" as typopanelvars;

$igMenuGroupDefaultPadding: 20px;

.ig-menu-group {
  padding: 0;
  border-bottom: 1px solid var(--ig-color-card-border);

  &:last-child {
    border: 0;
  }

  &__headline {
    // if ever used, this should be an H5
    //display: block;
    padding: 0 $igMenuGroupDefaultPadding 5px;
    //color: var(--h5-color);
  }

  &__nav {
    margin: 0;
    padding: 0;
    list-style: none;

    &-item {
      margin: 0;
      padding: 7px 5px;
      list-style: none;
      border-bottom: 1px solid var(--ig-color-card-border);

      &:last-child {
        border: 0;
      }

      &--withoutborder {
        border-bottom: none !important;

        & + .ig-menu-group__nav-item {
          padding-top: 0;
        }
      }

      &--active {
        .ig-menu-group__nav-link {
          color: var(--ig-color-menu-foreground-highlight);
          .main-menu-colors & {
            color: var(--ig-color-main-menu-foreground-highlight);
          }
        }
      }

      &--disabled {
        .ig-menu-group__nav-link {
          pointer-events: none;
          opacity: 0.6;
        }
      }

      &--subline {
        display: block;
        font-size: typopanelvars.$font-size-menu-secondLevel;
        color: var(--ig-color-menu-foreground-light);
        .main-menu-colors & {
          color: var(--ig-color-main-menu-foreground-light);
        }
        margin-top: -3px;

        & + .ig-menu-group__nav-item--subline {
          margin-top: 5px;
        }
      }

      &--icon {
        display: flex !important;
        align-items: center;
        justify-content: center;
        font-size: typopanelvars.$icon-size-menu;
        width: 30px;
        height: 30px;
        flex-shrink: 0;
      }

      &-label {
        display: flex;
        align-items: center;
        min-height: 30px;
        font-weight: typopanelvars.$font-weight-bold;
      }
    }

    &-link {
      color: var(--ig-color-menu-foreground)!important;
      .main-menu-colors & {
        color: var(--ig-color-main-menu-foreground)!important;
      }
      display: flex;
      gap: 5px;
      align-items: flex-start;
      padding: 10px 16px;
      border-radius: base_variables.$igBorderRadiusM;
      position: relative;
      text-decoration: none;

      &:hover, &:focus-within {
        cursor: pointer;
        background-color: var(--ig-color-menu-background-hover);
      }

      a {
        // link in link
        //color: var(--text-color)!important;

        &:hover, &:focus-within {
          color: var(--ig-color-menu-foreground)!important;
          .main-menu-colors & {
            color: var(--ig-color-main-menu-foreground)!important;
          }
        }
      }
    }
  }

  // Menu in p-Overlay
  // STILL IN USE???
  .overlaypanel--menu & {
    padding: 0;
    border: 0;

    .ig-menu-group__nav-link {
      padding: 4px 25px 4px 36px;
      line-height: 22px;
      font-size: typopanelvars.$font-size-content-default;

      > i {
        left: 12px;
      }

      &:hover, &:focus-within {
        background: var(--ig-color-menu-background-hover) !important;
        color: var(--ig-color-menu-foreground) !important;
      }
    }
  }
}
