@use "../../../variables/general" as general;
@use "../../../variables/misc" as misc;
.p-progressbar {
    border: misc.$progressBarBorder;
    height: misc.$progressBarHeight;
    background: misc.$progressBarBg;
    border-radius: general.$borderRadius;

    .p-progressbar-value {
        border: 0 none;
        margin: 0;
        background: misc.$progressBarValueBg;
    }

    .p-progressbar-label {
        color: var(--ig-text-color);
        line-height: misc.$progressBarHeight;
    }
}
