@use "../../../variables/panel" as panel;
@use "../../../variables/general" as general;
@use "../../../variables/data" as data;
.p-dataview {
    .p-paginator-top {
        border-width: data.$tableTopPaginatorBorderWidth;
        border-radius: 0;
    }

    .p-paginator-bottom {
        border-width: data.$tableBottomPaginatorBorderWidth;
        border-radius: 0;
    }

    .p-dataview-header {
        background: data.$tableHeaderBg;
        color: data.$tableHeaderTextColor;
        border: data.$tableHeaderBorder;
        border-width: data.$tableHeaderBorderWidth;
        padding: data.$tableHeaderPadding;
        font-weight: data.$tableHeaderFontWeight;
    }

    .p-dataview-content {
        background: data.$tableBodyRowBg;
        color: data.$tableBodyRowTextColor;
        border: data.$dataViewContentBorder;
        padding: data.$dataViewContentPadding;
    }

    &.p-dataview-list {
        .p-dataview-content {
            > .p-grid > div {
                border: data.$dataViewListItemBorder;
                border-width: data.$dataViewListItemBorderWidth;
            }
        }
    }

    .p-dataview-footer {
        background: data.$tableFooterBg;
        color: data.$tableFooterTextColor;
        border: data.$tableFooterBorder;
        border-width: data.$tableFooterBorderWidth;
        padding: data.$tableFooterPadding;
        font-weight: data.$tableFooterFontWeight;
        border-bottom-left-radius: general.$borderRadius;
        border-bottom-right-radius: general.$borderRadius;
    }

    .p-dataview-loading-icon {
        font-size: general.$loadingIconFontSize;
    }

    .p-dataview-emptymessage {
        padding: panel.$panelContentPadding;
    }
}
