@use "../../lib/foundations/typographie/typo.panel.vars" as typopanelvars;
.favorite-switch {
    cursor: pointer;
    color: var(--text-secondary-color);
    margin-left: 5px;
    font-size: typopanelvars.$font-size-content-default;

    &:hover, &:focus-within {
        color: var(--primary-color);
    }
}
