@use "../../../variables/menu" as menu;
@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/form" as form;
.p-cascadeselect {
    background: form.$inputBg;
    border: form.$inputBorder;
    transition: general.$formElementTransition;
    border-radius: general.$borderRadius;

    &:not(.p-disabled):hover,
    &:not(.p-disabled):focus-within {
        border-color: form.$inputHoverBorderColor;
    }

    &:not(.p-disabled).p-focus {
        @include mixins.focused-input();
    }

    .p-cascadeselect-label {
        background: transparent;
        border: 0 none;
        padding: form.$inputPadding;

        &.p-placeholder {
            color: form.$inputPlaceholderTextColor;
        }

        &:enabled:focus {
            outline: 0 none;
            box-shadow: none;
        }
    }

    .p-cascadeselect-trigger {
        background: transparent;
        color: form.$inputIconColor;
        width: form.$inputGroupAddOnMinWidth;
        border-top-right-radius: general.$borderRadius;
        border-bottom-right-radius: general.$borderRadius;
    }

    &.p-error,
    &.p-invalid {
        @include mixins.invalid-input();
    }
}

.p-cascadeselect-panel {
    background: form.$inputOverlayBg;
    color: form.$inputListTextColor;
    border: form.$inputOverlayBorder;
    border-radius: general.$borderRadius;
    box-shadow: form.$inputOverlayShadow;

    .p-cascadeselect-items {
        padding: form.$inputListPadding;

        .p-cascadeselect-item {
            margin: form.$inputListItemMargin;
            border: form.$inputListItemBorder;
            color: form.$inputListItemTextColor;
            background: form.$inputListItemBg;
            transition: general.$listItemTransition;
            border-radius: form.$inputListItemBorderRadius;

            .p-cascadeselect-item-content {
                padding: form.$inputListItemPadding;

                &:focus {
                    @include mixins.focused-listitem();
                }
            }

            &.p-highlight {
                color: general.$highlightTextColor;
                background: general.$highlightBg;
            }

            &:not(.p-highlight):not(.p-disabled):hover,
            &:not(.p-highlight):not(.p-disabled):focus-within {
                color: form.$inputListItemTextHoverColor;
                background: form.$inputListItemHoverBg;
            }

            .p-cascadeselect-group-icon {
                font-size: menu.$menuitemSubmenuIconFontSize;
            }
        }
    }
}

.p-input-filled {
    .p-cascadeselect {
        background: form.$inputFilledBg;

        &:not(.p-disabled):hover,
        &:not(.p-disabled):focus-within {
            background-color: form.$inputFilledHoverBg;
        }

        &:not(.p-disabled).p-focus {
            background-color: form.$inputFilledFocusBg;
        }
    }
}
