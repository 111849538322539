@use "../variables/menu" as menu;
@use "../variables/form" as form;
@use "../variables/general" as general;
@mixin icon-override($icon) {
	&:before {
		content: $icon;
	}
}

@mixin focused() {
	outline: general.$focusOutline;
    outline-offset: general.$focusOutlineOffset;
    box-shadow: general.$focusShadow;
}

@mixin focused-input() {
	@include focused();
	border-color: form.$inputFocusBorderColor;
}

@mixin focused-listitem() {
	outline: general.$focusOutline;
    outline-offset: general.$focusOutlineOffset;
    box-shadow: form.$inputListItemFocusShadow;
}

@mixin invalid-input() {
	border-color: form.$inputErrorBorderColor;
}

@mixin menuitem-link {
	padding: menu.$menuitemPadding;
	color: menu.$menuitemTextColor;
    border-radius: menu.$menuitemBorderRadius;
    transition: general.$listItemTransition;
    user-select: none;
    min-height: 2rem;

	.p-menuitem-text {
        color: menu.$menuitemTextColor;
	}

	.p-menuitem-icon {
		color: menu.$menuitemIconColor;
		margin-right: general.$inlineSpacing;
    }

    .p-submenu-icon {
        color: menu.$menuitemIconColor;
    }

	&:not(.p-disabled):hover,
	&:not(.p-disabled):focus-within {
		background: menu.$menuitemHoverBg;

		.p-menuitem-text {
			color: menu.$menuitemTextHoverColor;
		}

		.p-menuitem-icon {
			color: menu.$menuitemIconHoverColor;
        }

        .p-submenu-icon {
            color: menu.$menuitemIconHoverColor;
        }
	}

	&:focus {
		@include focused-listitem();
	}
}

@mixin horizontal-rootmenuitem-link {
	padding: menu.$horizontalMenuRootMenuitemPadding;
	color: menu.$horizontalMenuRootMenuitemTextColor;
    border-radius: menu.$horizontalMenuRootMenuitemBorderRadius;
    transition: general.$listItemTransition;
    user-select: none;

	.p-menuitem-text {
        color: menu.$horizontalMenuRootMenuitemTextColor;
	}

	.p-menuitem-icon {
		color: menu.$horizontalMenuRootMenuitemIconColor;
		margin-right: general.$inlineSpacing;
    }

    .p-submenu-icon {
        color: menu.$horizontalMenuRootMenuitemIconColor;
        margin-left: general.$inlineSpacing;
    }

	&:not(.p-disabled):hover,
	&:not(.p-disabled):focus-within
  {
		background: menu.$horizontalMenuRootMenuitemHoverBg;

		.p-menuitem-text {
			color: menu.$horizontalMenuRootMenuitemTextHoverColor;
		}

		.p-menuitem-icon {
			color: menu.$horizontalMenuRootMenuitemIconHoverColor;
        }

        .p-submenu-icon {
            color: menu.$horizontalMenuRootMenuitemIconHoverColor;
        }
	}

	&:focus {
		@include focused-listitem();
	}
}

@mixin placeholder {
	::-webkit-input-placeholder {
		@content
	}
	:-moz-placeholder {
		@content
	}
	::-moz-placeholder {
		@content
	}
	:-ms-input-placeholder {
		@content
	}
}

@mixin scaledPadding($val, $scale) {
    padding: nth($val, 1) * $scale nth($val, 2) * $scale;
}

@mixin scaledFontSize($val, $scale) {
    font-size: $val * $scale;
}

@mixin nested-submenu-indents($val, $index, $length) {
    ul {
        li {
            a {
                padding-left: $val * ($index + 1);
            }

            @if $index < $length {
                @include nested-submenu-indents($val, $index + 2, $length);
            }
        }
    }
}

@mixin action-icon() {
    width: general.$actionIconWidth;
    height: general.$actionIconHeight;
    color: general.$actionIconColor;
    border: general.$actionIconBorder;
    background: general.$actionIconBg;
    border-radius: general.$actionIconBorderRadius;
    transition: general.$actionIconTransition;

    &:enabled:hover,
    &:enabled:focus-within {
        color: general.$actionIconHoverColor;
        border-color: general.$actionIconHoverBorderColor;
        background: general.$actionIconHoverBg;
    }

    &:focus {
        @include focused();
    }
}
