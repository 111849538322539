@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/menu" as menu;
.p-menu {
    padding: menu.$verticalMenuPadding;
    background: menu.$menuBg;
    color: menu.$menuTextColor;
    border: menu.$menuBorder;
    border-radius: general.$borderRadius;
    width: menu.$menuWidth;

    .p-menuitem-link {
        @include mixins.menuitem-link();
    }

    &.p-menu-overlay {
        background: menu.$overlayMenuBg;
        border: menu.$overlayMenuBorder;
        box-shadow: menu.$overlayMenuShadow;
    }

    .p-submenu-header {
        margin: menu.$submenuHeaderMargin;
        padding: menu.$submenuHeaderPadding;
        color: menu.$submenuHeaderTextColor;
        background: menu.$submenuHeaderBg;
        font-weight: menu.$submenuHeaderFontWeight;
        border-top-right-radius: menu.$submenuHeaderBorderRadius;
        border-top-left-radius: menu.$submenuHeaderBorderRadius;
    }

    .p-menu-separator {
        border-top: general.$divider;
        margin: menu.$menuSeparatorMargin;
    }

    .p-menuitem {
        margin: menu.$verticalMenuitemMargin;

        &:last-child {
            margin: 0;
        }
    }

    .p-menuitem-text {
      white-space: nowrap;
    }

    .p-focus {
      background: menu.$menuitemHoverBg;
      .p-menuitem-text {
        color: menu.$menuitemTextHoverColor;
      }
      .p-menuitem-icon {
        color: menu.$menuitemIconHoverColor;
      }
    }

    .p-menuitem-icon[class*="icon-"] {
        transform: scale(1.25);
    }
}
