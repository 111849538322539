@use "../../../../lib/foundations/typographie/typo.panel.vars" as typopanelvars;
@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/form" as form;
.p-radiobutton {
    width: form.$radiobuttonWidth;
    height: form.$radiobuttonHeight;

    .p-radiobutton-box {
        border: form.$radiobuttonBorder;
        background: form.$inputBg;
        width: form.$radiobuttonWidth;
        height: form.$radiobuttonHeight;
        color: var(--ig-text-color);
        border-radius: 50%;
        transition: general.$formElementTransition;

        &:not(.p-disabled):not(.p-highlight):hover,
        &:not(.p-disabled):not(.p-highlight):focus-within {
            border-color: form.$inputHoverBorderColor;
        }

        &:not(.p-disabled).p-focus {
            @include mixins.focused-input();
        }

        .p-radiobutton-icon {
            width: form.$radiobuttonIconSize;
            height: form.$radiobuttonIconSize;
            transition-duration: general.$transitionDuration;
            background-color: form.$radiobuttonIconActiveColor;
        }

        &.p-highlight {
            border-color: form.$radiobuttonActiveBorderColor;
            background: form.$radiobuttonActiveBg;

            &:not(.p-disabled):hover,
            &:not(.p-disabled):focus-within {
                border-color: form.$radiobuttonActiveHoverBorderColor;
                background: form.$radiobuttonActiveHoverBg;
                color: form.$radiobuttonIconActiveHoverColor;
            }
        }
    }

    &.p-error > .p-radiobutton-box,
    &.p-invalid > .p-radiobutton-box {
        @include mixins.invalid-input();
    }

    &+label {
      font-weight: typopanelvars.$font-weight-bold !important;
    }

    &.p-radiobutton-checked +label {
      color: form.$radiobuttonIconActiveColor
    }
}

p-radiobutton.ng-dirty.ng-invalid > .p-radiobutton > .p-radiobutton-box {
    @include mixins.invalid-input();
}

.p-input-filled {
    .p-radiobutton {
        .p-radiobutton-box {
            background-color: form.$inputFilledBg;

            &:not(.p-disabled):hover, &:focus-within {
                background-color: form.$inputFilledHoverBg;
            }

            &.p-highlight {
                background: form.$radiobuttonActiveBg;

                &:not(.p-disabled):hover, &:focus-within {
                    background: form.$radiobuttonActiveHoverBg;
                }
            }
        }
    }
}

.p-radiobutton-label {
    margin-left: general.$inlineSpacing;
}

@if (general.$highlightBg == form.$radiobuttonActiveBg) {
    .p-highlight {
        .p-radiobutton {
            .p-radiobutton-box {
                border-color: form.$radiobuttonIconActiveColor;
            }
        }
    }
}
