@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/form" as form;
.p-listbox {
    background: form.$inputListBg;
    color: form.$inputListTextColor;
    border: form.$inputListBorder;
    border-radius: general.$borderRadius;

    .p-listbox-header {
        padding: form.$inputListHeaderPadding;
        border-bottom: form.$inputListHeaderBorder;
        color: form.$inputListHeaderTextColor;
        background: form.$inputListHeaderBg;
        margin: form.$inputListHeaderMargin;
        border-top-right-radius: general.$borderRadius;
        border-top-left-radius: general.$borderRadius;

        .p-listbox-filter {
            padding-right: nth(form.$inputPadding, 2) + general.$primeIconFontSize;
        }

        .p-listbox-filter-icon {
            right: nth(form.$inputPadding, 2);
            color: form.$inputIconColor;
        }

        .p-checkbox {
            margin-right: general.$inlineSpacing;
        }
    }

    .p-listbox-list {
        padding: form.$inputListPadding;

        .p-listbox-item {
            margin: form.$inputListItemMargin;
            padding: form.$inputListItemPadding;
            border: form.$inputListItemBorder;
            color: form.$inputListItemTextColor;
            transition: general.$listItemTransition;
            border-radius: form.$inputListItemBorderRadius;

            &.p-highlight {
                color: general.$highlightTextColor;
                background: general.$highlightBg;
            }

            &:focus {
                @include mixins.focused-listitem();
            }

            .p-checkbox {
                margin-right: general.$inlineSpacing;
            }
        }
    }

    &:not(.p-disabled) {
        .p-listbox-item {
            &:not(.p-highlight):not(.p-disabled):hover,
            &:not(.p-highlight):not(.p-disabled):focus-within {
                color: form.$inputListItemTextHoverColor;
                background: form.$inputListItemHoverBg;
            }
        }
    }

    &.p-error,
    &.p-invalid {
        @include mixins.invalid-input();
    }
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
    @include mixins.invalid-input();
}
