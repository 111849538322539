@use "../../../variables/general" as general;
@use "../../../variables/panel" as panel;
.p-toolbar {
    background: panel.$panelHeaderBg;
    border: panel.$panelHeaderBorder;
    padding: panel.$panelHeaderPadding;
    border-radius: general.$borderRadius;

    .p-toolbar-separator {
        margin: 0 general.$inlineSpacing;
    }
}
