@use "../../../variables/form" as form;
@use "../../mixins" as mixins;
@use "../../../variables/data" as data;
@use "../../../variables/general" as general;
@use "../../../variables/panel" as panel;
.p-tree {
    border: panel.$panelContentBorder;
    background: panel.$panelContentBg;
    color: panel.$panelContentTextColor;
    padding: panel.$panelContentPadding;
    border-radius: general.$borderRadius;

    .p-tree-container {
        .p-treenode {
            padding: data.$treeNodePadding;

            .p-treenode-content {
                border-radius: general.$borderRadius;
                transition: general.$listItemTransition;
                padding: data.$treeNodeContentPadding;

                .p-tree-toggler {
                    margin-right: general.$inlineSpacing;
                    @include mixins.action-icon();
                }

                .p-treenode-icon {
                    margin-right: general.$inlineSpacing;
                    color: data.$treeNodeIconColor;
                }

                .p-checkbox {
                    margin-right: general.$inlineSpacing;

                    .p-indeterminate {
                        .p-checkbox-icon {
                            color: var(--ig-text-color);
                        }
                    }
                }

                &:focus {
                    @include mixins.focused();
                }

                &.p-highlight {
                    background: general.$highlightBg;
                    color: general.$highlightTextColor;

                    .p-tree-toggler,
                    .p-treenode-icon {
                        color: general.$highlightTextColor;

                        &:hover, &:focus-within {
                            color: general.$highlightTextColor;
                        }
                    }
                }

                &.p-treenode-selectable:not(.p-highlight):hover,
                &.p-treenode-selectable:not(.p-highlight):focus-within {
                    background: form.$inputListItemHoverBg;
                    color: form.$inputListItemTextHoverColor;
                }

                &.p-treenode-dragover {
                    background: form.$inputListItemHoverBg;
                    color: form.$inputListItemTextHoverColor;
                }
            }
        }
    }

    .p-tree-filter-container {
        margin-bottom: general.$inlineSpacing;

        .p-tree-filter {
            width: 100%;
            padding-right: nth(form.$inputPadding, 2) + general.$primeIconFontSize;
        }

        .p-tree-filter-icon {
            right: nth(form.$inputPadding, 2);
            color: form.$inputIconColor;
        }
    }

    .p-treenode-children {
        padding: data.$treeNodeChildrenPadding;
    }

    .p-tree-loading-icon {
        font-size: general.$loadingIconFontSize;
    }

    .p-treenode-droppoint {
        &.p-treenode-droppoint-active {
            background-color: var(--primary-color-darker20);
        }
    }

    &.p-tree-horizontal {
        .p-treenode {
            .p-treenode-content {
                border-radius: general.$borderRadius;
                border: panel.$panelContentBorder;
                background-color: panel.$panelContentBg;
                color: panel.$panelContentTextColor;
                padding: data.$treeNodeContentPadding;
                transition: general.$listItemTransition;

                &.p-highlight {
                    background-color: general.$highlightBg;
                    color: general.$highlightTextColor;

                    .p-treenode-icon {
                        color: general.$highlightTextColor;
                    }
                }

                .p-tree-toggler {
                    margin-right: general.$inlineSpacing;
                }

                .p-treenode-icon {
                    color: data.$treeNodeIconColor;
                    margin-right: general.$inlineSpacing;
                }

                .p-checkbox {
                    margin-right: general.$inlineSpacing;
                }

                .p-treenode-label:not(.p-highlight):hover,
                .p-treenode-label:not(.p-highlight):focus-within {
                    background-color: inherit;
                    color: inherit;
                }

                &.p-treenode-selectable:not(.p-highlight):hover,
                &.p-treenode-selectable:not(.p-highlight):focus-within {
                    background: form.$inputListItemHoverBg;
                    color: form.$inputListItemTextHoverColor;
                }

                &:focus {
                    @include mixins.focused();
                }
            }
        }
    }
}
