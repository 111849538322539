
body {
  .p-dynamicdialog,
  .p-dialog {
    background: var(--ig-color-white);

    .p-dialog-titlebar {
      border: none;
      border-bottom: none;
      background: var(--ig-color-white);

      .p-dialog-title {
        font-size: 18px;
      }
    }

    .p-dialog-content {
      border: none;
      padding: 1em;
    }

    .p-dialog-footer {
      border: none;
    }
  }
}
