@use "sass:list";
@use "sass:map";
@use "sass:meta";

/// Mixin to manage responsive breakpoints
/// @param {String} $breakpoint - Breakpoint name and optional direction ("up" or "down")
/// @param {String} $direction - up or down or only (only is the default)
/// @require $igBreakpoints

$igBreakpoints: (
  'small': (
    'min': 320px,
    'max': 767px
  ),
  'medium': (
    'min': 768px,
    'max': 1199px
  ),
  'large': (
    'min': 1200px,
    'max': 1919px
  ),
  'xlarge': (
    'min': 1920px,
    'max': 99999px
  )
) !default;

@mixin respond-to($breakpoint) {
    $direction: only;

    @if meta.type-of($breakpoint) == 'list' {
        $direction: list.nth($breakpoint, 2);
        $breakpoint: list.nth($breakpoint, 1);
    }
    // If the key exists in the map
    @if map.has-key($igBreakpoints, $breakpoint) {
        // Prints a media query based on the value
        @if ($direction == up) {
            @media screen and (min-width: map.get(map.get($igBreakpoints, $breakpoint), 'min')) {
                @content;
            }
        }
        @if ($direction == down) {
            @media screen and (max-width: map.get(map.get($igBreakpoints, $breakpoint), 'min') - 1px) {
                @content;
            }
        }

        @if ($direction == only) {
            @media screen and (min-width: map.get(map.get($igBreakpoints, $breakpoint), 'min')) and (max-width: map.get(map.get($igBreakpoints, $breakpoint), 'max')) {
                @content;
            }
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map.keys($igBreakpoints)}.";
    }
}






/// Mixin to manage responsive breakpoints (reversed respond-to mixin)
/// @param {String} $breakpoint - Breakpoint name and optional direction ("up" or "down")
/// @param {String} $direction - up or down or only (only is the default)
/// @require $igBreakpoints
@mixin not-respond-to($breakpoint) {
    $direction: only;

    @if type-of($breakpoint) == 'list' {
        $direction: nth($breakpoint, 2);
        $breakpoint: nth($breakpoint, 1);
    }
    // If the key exists in the map
    @if map.has-key($igBreakpoints, $breakpoint) {
        // Prints a media query based on the value
        @if ($direction == up) {
            @media screen and (max-width: map.get(map.get($igBreakpoints, $breakpoint), 'min') - 1px) {
                @content;
            }
        }
        @if ($direction == down) {
            @media screen and (min-width: map.get(map.get($igBreakpoints, $breakpoint), 'min')) {
                @content;
            }
        }


        @if ($direction == only) {
            @media
              screen and ( max-width: map.get(map.get($igBreakpoints, $breakpoint), 'min') - 1px),
              screen and ( min-width: map.get(map.get($igBreakpoints, $breakpoint), 'max') + 1px)  {
                @content;
            }
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map.keys($igBreakpoints)}.";
    }
}

@mixin response-classes() {
  .gs-show-small-down, .ig-show-small-down {
    @include not-respond-to(small down) { display: none!important; }
  }

  .gs-show-small, .ig-show-small {
    @include not-respond-to(small) { display: none!important; }
  }

  .gs-show-small-up, .ig-show-small-up {
    @include not-respond-to(small up) { display: none!important; }
  }

  .gs-show-medium-down, .ig-show-medium-down {
    @include not-respond-to(medium down) { display: none!important; }
  }

  .gs-show-medium, .ig-show-medium {
    @include not-respond-to(medium) { display: none!important; }
  }

  .gs-show-medium-up, .ig-show-medium-up {
    @include not-respond-to(medium up) { display: none!important; }
  }

  .gs-show-large-down, .ig-show-large-down {
    @include not-respond-to(large down) { display: none!important; }
  }

  .gs-show-large, .ig-show-large {
    @include not-respond-to(large) { display: none!important; }
  }

  .gs-show-large-up, .ig-show-large-up {
    @include not-respond-to(large up) { display: none!important; }
  }

  .gs-show-xlarge-down, .ig-show-xlarge-down {
    @include not-respond-to(xlarge down) { display: none!important; }
  }

  .gs-show-xlarge, .ig-show-xlarge {
    @include not-respond-to(xlarge) { display: none!important; }
  }

  .gs-show-xlarge-up, .ig-show-xlarge-up {
    @include not-respond-to(xlarge up) { display: none!important; }
  }

  .gs-hide-small-down, .ig-hide-small-down {
    @include respond-to(small down) { display: none!important; }
  }

  .gs-hide-small, .ig-hide-small {
    @include respond-to(small) { display: none!important; }
  }

  .gs-hide-small-up, .ig-hide-small-up {
    @include respond-to(small up) { display: none!important; }
  }

  .gs-hide-medium-down, .ig-hide-medium-down {
    @include respond-to(medium down) { display: none!important; }
  }

  .gs-hide-medium, .ig-hide-medium {
    @include respond-to(medium) { display: none!important; }
  }

  .gs-hide-medium-up, .ig-hide-medium-up {
    @include respond-to(medium up) { display: none!important; }
  }

  .gs-hide-large-down, .ig-hide-large-down {
    @include respond-to(large down) { display: none!important; }
  }

  .gs-hide-large, .ig-hide-large {
    @include respond-to(large) { display: none!important; }
  }

  .gs-hide-large-up, .ig-hide-large-up {
    @include respond-to(large up) { display: none!important; }
  }

  .gs-hide-xlarge-down, .ig-hide-xlarge-down {
    @include respond-to(xlarge down) { display: none!important; }
  }

  .gs-hide-xlarge, .ig-hide-xlarge {
    @include respond-to(xlarge) { display: none!important; }
  }

  .gs-hide-xlarge-up, .ig-hide-xlarge-up {
    @include respond-to(xlarge up) { display: none!important; }
  }
}
