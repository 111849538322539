@use "../../lib/foundations/typographie/typo.panel.vars" as typopanelvars;
@use "../variables/general" as general;

body {
    .ig-inputnumber {
        // background-color: var(--ig-color-white);

        .p-inputnumber-input {
            border-right-color: transparent;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .p-inputnumber-button-group {
            border: 1px solid var(--input-form-border);
            border-top-right-radius: general.$borderRadius;
            border-bottom-right-radius: general.$borderRadius;
            border-left: medium none;
        }

        .p-inputnumber-button {
            background: transparent;
            border: none;
            color: var(--icons-color);
            font-weight: typopanelvars.$font-weight-normal;
            padding: 0 4px !important;
            width: auto !important;
            line-height: 1.125rem;

            &:hover, &:focus-within {
                color: var(--primary-color) !important;
                background: transparent !important;
            }
          }
    }
}
