@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../public/base_variables" as base_variables;
@use "../../../variables/form" as form;
.p-checkbox {
    width: form.$checkboxWidth;
    height: form.$checkboxHeight;

    .p-checkbox-box {
        border: form.$checkboxBorder;
        background: transparent;
        width: form.$checkboxWidth;
        height: form.$checkboxHeight;
        color: var(--ig-text-color);
        border-radius: base_variables.$igBorderRadiusS; // $borderRadius;
        transition: general.$formElementTransition;

        .p-checkbox-icon {
            transition-duration: general.$transitionDuration;
            color: form.$checkboxIconActiveColor;
            font-size: form.$checkboxIconFontSize;
        }

        &.p-highlight {
            border-color: form.$checkboxActiveBorderColor;
            background: form.$checkboxActiveBg;
        }
    }

    &:not(.p-checkbox-disabled) {
        .p-checkbox-box {
            &:hover, &:focus-within {
                border-color: form.$inputHoverBorderColor;
            }

            &.p-focus {
                @include mixins.focused-input();
            }

            &.p-highlight:hover,
            &.p-highlight:focus-within {
                border-color: form.$checkboxActiveHoverBorderColor;
                background: form.$checkboxActiveHoverBg;
                color: form.$checkboxIconActiveHoverColor;
            }
        }
    }

    &.p-error > .p-checkbox-box,
    &.p-invalid > .p-checkbox-box {
        @include mixins.invalid-input();
    }
}

p-checkbox.ng-dirty.ng-invalid > .p-checkbox > .p-checkbox-box {
    @include mixins.invalid-input();
}

.p-input-filled {
    .p-checkbox {
        .p-checkbox-box {
            background-color: form.$inputFilledBg;

            &.p-highlight {
                background: form.$checkboxActiveBg;
            }
        }

        &:not(.p-checkbox-disabled) {
            .p-checkbox-box {
                &:hover, &:focus-within {
                    background-color: form.$inputFilledHoverBg;
                }

                &.p-highlight:hover,
                &.p-highlight:focus-within {
                    background: form.$checkboxActiveHoverBg;
                }
            }
        }
    }
}

label.p-checkbox-label {
    margin-left: general.$inlineSpacing;
    color: var(--ig-color-label)!important;
}

@if (general.$highlightBg == form.$checkboxActiveBg) {
    .p-highlight {
        .p-checkbox {
            .p-checkbox-box {
                border-color: form.$checkboxIconActiveColor;
            }
        }
    }
}
