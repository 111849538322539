@use "../../../variables/general" as general;
@use "../../../variables/form" as form;
@use "../../mixins" as mixins;
.p-calendar {
    &.p-error > .p-inputtext,
    &.p-invalid > .p-inputtext {
        @include mixins.invalid-input();
    }
}

p-calendar.ng-dirty.ng-invalid > .p-calendar > .p-inputtext {
    @include mixins.invalid-input();
}

.p-datepicker {
    padding: form.$calendarPadding;
    background: form.$calendarInlineBg;
    color: form.$calendarTextColor;
    border: form.$calendarBorder;
    border-radius: general.$borderRadius;

    &:not(.p-datepicker-inline) {
        background: form.$calendarBg;
        border: form.$calendarOverlayBorder;
        box-shadow: form.$inputOverlayShadow;

        .p-datepicker-header {
            background: form.$calendarHeaderBg;
        }
    }

    .p-datepicker-header {
        padding: form.$calendarHeaderPadding;
        color: form.$calendarHeaderTextColor;
        background: form.$calendarInlineHeaderBg;
        font-weight: form.$calendarHeaderFontWeight;
        margin: form.$inputListHeaderMargin;
        border-bottom: form.$calendarHeaderBorder;
        border-top-right-radius: general.$borderRadius;
        border-top-left-radius: general.$borderRadius;

        .p-datepicker-prev,
        .p-datepicker-next {
            @include mixins.action-icon();
        }

        .p-datepicker-title {
            line-height: general.$actionIconHeight;

            select {
                transition: general.$formElementTransition;

                &:focus {
                    @include mixins.focused-input();
                }
            }

            .p-datepicker-month {
                margin-right: general.$inlineSpacing;
            }
        }
    }

    table {
        font-size: general.$fontSize;
        margin: form.$calendarTableMargin;

        th {
            padding: form.$calendarHeaderCellPadding;

            > span {
                width: form.$calendarCellDateWidth;
                height: form.$calendarCellDateHeight;
            }
        }

        td {
            padding: form.$calendarCellDatePadding;

            > span {
                width: form.$calendarCellDateWidth;
                height: form.$calendarCellDateHeight;
                border-radius: form.$calendarCellDateBorderRadius;
                transition: general.$listItemTransition;
                border: form.$calendarCellDateBorder;

                &.p-highlight {
                    color: general.$highlightTextColor;
                    background: general.$highlightBg;
                }

                &:focus {
                    @include mixins.focused();
                }
            }

            &.p-datepicker-today {
                > span {
                    background: form.$calendarCellDateTodayBg;
                    color: form.$calendarCellDateTodayTextColor;
                    border-color: form.$calendarCellDateTodayBorderColor;

                    &.p-highlight {
                        color: general.$highlightTextColor;
                        background: general.$highlightBg;
                    }
                }
            }
        }
    }

    .p-datepicker-buttonbar {
        padding: form.$calendarButtonBarPadding;
        border-top: general.$divider;

        .p-button {
            width: auto;
        }
    }

    .p-timepicker {
        border-top: general.$divider;
        padding: form.$calendarTimePickerPadding;

        button {
            @include mixins.action-icon();

            &:last-child {
                margin-top: .2em;
            }
        }

        span {
            font-size: form.$calendarTimePickerTimeFontSize;
        }

        > div {
            padding: form.$calendarTimePickerElementPadding;
        }
    }

    &.p-datepicker-timeonly {
        .p-timepicker {
            border-top: 0 none;
        }
    }

    .p-monthpicker {
        margin: form.$calendarTableMargin;

        .p-monthpicker-month {
            padding: form.$calendarCellDatePadding;
            transition: general.$listItemTransition;
            border-radius: general.$borderRadius;

            &.p-highlight {
                color: general.$highlightTextColor;
                background: general.$highlightBg;
            }
        }
    }

    &.p-datepicker-multiple-month {
        .p-datepicker-group {
            border-right: general.$divider;
            padding-right: form.$calendarPadding;
            padding-left: form.$calendarPadding;
            padding-top: 0;
            padding-bottom: 0;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
                border-right: 0 none;
            }
        }
    }

    &:not(.p-disabled) {
        table {
            td {
                span:not(.p-highlight):not(.p-disabled) {
                    &:hover, &:focus-within {
                        background: form.$calendarCellDateHoverBg;
                    }

                    &:focus {
                        @include mixins.focused();
                    }
                }
            }
        }

        .p-monthpicker {
            .p-monthpicker-month {
                &:not(.p-highlight):not(.p-disabled) {
                    &:hover, &:focus-within {
                        background: form.$calendarCellDateHoverBg;
                    }

                    &:focus {
                        @include mixins.focused();
                    }
                }
            }
        }
    }

}

@media screen and (max-width: form.$calendarBreakpoint) {
    .p-datepicker {
        table {
            th, td {
                padding: form.$calendarCellDatePaddingSM;
            }
        }
    }
}
