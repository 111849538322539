@use "../../../variables/menu" as menu;
@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/form" as form;
.p-dropdown {
    background: var(--ig-color-form-input-background);
    border: form.$inputBorder;
    transition: general.$formElementTransition;
    border-radius: general.$borderRadius;

    &:not(.p-disabled):hover,
    &:not(.p-disabled):focus-within {
        border-color: form.$inputHoverBorderColor;
    }

    &:not(.p-disabled).p-focus {
        @include mixins.focused-input();
    }

    &.p-dropdown-clearable {
        .p-dropdown-label {
            padding-right: nth(form.$inputPadding, 2) + general.$primeIconFontSize;
        }
    }

    .p-dropdown-label {
        background: transparent;
        border: 0 none;

        color: var(--ig-color-form-dropdown-selected);
        &.p-placeholder {
            color: var(--ig-color-form-dropdown-placeholder);
        }

        &:enabled:focus {
            outline: 0 none;
            box-shadow: none;
        }
    }

    .p-dropdown-trigger {
        background: transparent;
        color: form.$inputIconColor;
        width: form.$inputGroupAddOnMinWidth;
        border-top-right-radius: general.$borderRadius;
        border-bottom-right-radius: general.$borderRadius;
    }

    .p-dropdown-trigger-icon,
    .p-dropdown-clear-icon {
      color: var(--ig-color-form-dropdown-selected);
      font-size: 20px;
    }

    .p-dropdown-clear-icon {
        right: form.$inputGroupAddOnMinWidth;
    }
}

.p-dropdown-panel {
    background: form.$inputOverlayBg;
    color: form.$inputListTextColor;
    border: form.$inputOverlayBorder;
    border-radius: general.$borderRadius;
    box-shadow: form.$inputOverlayShadow;

    .p-dropdown-header {
        padding: form.$inputListHeaderPadding;
        border-bottom: form.$inputListHeaderBorder;
        color: form.$inputListHeaderTextColor;
        background: form.$inputOverlayHeaderBg;
        margin: form.$inputListHeaderMargin;
        border-top-right-radius: general.$borderRadius;
        border-top-left-radius: general.$borderRadius;

        .p-dropdown-filter {
            padding-right: nth(form.$inputPadding, 2) + general.$primeIconFontSize;
        }

        .p-dropdown-filter-icon {
            right: nth(form.$inputPadding, 2);
            color: form.$inputIconColor;
        }
    }

    .p-dropdown-items {
        padding: form.$inputListPadding;

        .p-dropdown-item {
            margin: form.$inputListItemMargin;
            padding: form.$inputListItemPadding;
            border: form.$inputListItemBorder;
            color: form.$inputListItemTextColor;
            background: form.$inputListItemBg;
            transition: general.$listItemTransition;
            border-radius: form.$inputListItemBorderRadius;

            &.p-highlight {
                color: general.$highlightTextColor;
                background: general.$highlightBg;
            }

            &:not(.p-highlight):not(.p-disabled) {
              &:hover,&.p-focus, &:focus-within {
                color: form.$inputListItemTextHoverColor;
                background: form.$inputListItemHoverBg;
              }
            }
        }

        .p-dropdown-empty-message {
            padding: form.$inputListItemPadding;
            color: form.$inputListItemTextColor;
            background: form.$inputListItemBg;
        }

        .p-dropdown-item-group {
            margin: menu.$submenuHeaderMargin;
            padding: menu.$submenuHeaderPadding;
            color: var(--ig-color-dropdown-group-header-foreground);
            background: var(--ig-color-dropdown-group-header-background);
            font-weight: menu.$submenuHeaderFontWeight;
        }
    }

    &.p-error,
    &.p-invalid {
        @include mixins.invalid-input();
    }
}

p-dropdown.ng-dirty.ng-invalid,
p-dropdown.ng-invalid.ng-touched {
    > .p-dropdown {
        @include mixins.invalid-input();
    }
}
ig-dropdown.ng-dirty.ng-invalid,
ig-dropdown.ng-invalid.ng-touched {
    > .p-dropdown {
        @include mixins.invalid-input();
    }
}

.p-input-filled {
    .p-dropdown {
        background: form.$inputFilledBg;

        &:not(.p-disabled):hover,
        &:not(.p-disabled):focus-within {
            background-color: form.$inputFilledHoverBg;
        }

        &:not(.p-disabled).p-focus {
            background-color: form.$inputFilledFocusBg;
        }
    }
}
