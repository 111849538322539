@use "../../../variables/general" as general;
@use "../../../variables/form" as form;
@use "../../../variables/overlay" as overlay;
@use "../../../variables/panel" as panel;
.p-password-panel {
    padding: panel.$panelContentPadding;
    background: panel.$panelContentBg;
    color: panel.$panelContentTextColor;
    border: overlay.$overlayContentBorder;
    box-shadow: form.$inputOverlayShadow;
    border-radius: general.$borderRadius;

    .p-password-meter {
        margin-bottom: general.$inlineSpacing;
    }
}
