@use "../variables/misc" as misc;

.p-chip {
  .pi-chip-remove-icon {
    color: misc.$chipTextColor;
    &:hover {
      cursor: pointer;
    }
  }
}
