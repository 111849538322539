@use "mixins" as mixins;
@use "../variables/general" as general;
* {
	box-sizing: border-box;
}

.p-component {
    font-family: general.$fontFamily;
    font-size: general.$fontSize;
    font-weight: general.$fontWeight;
}

.p-component-overlay {
    background-color: general.$maskBg;
    transition-duration: general.$transitionDuration;
}

:not(.p-autocomplete) {
  &.p-disabled, &.p-component:disabled {
    opacity: general.$disabledOpacity;
  }
}


.p-error, .p-invalid {
    color: general.$errorColor;
}

.p-text-secondary {
    color: var(--text-secondary-color);
}

.pi {
    font-size: general.$primeIconFontSize;
}

.p-link {
    font-size: general.$fontSize;
    font-family: general.$fontFamily;
    border-radius: general.$borderRadius;

    &:focus {
        @include mixins.focused();
    }
}
