@use "../../mixins" as mixins;
@use "../../../variables/menu" as menu;
@use "../../../variables/general" as general;
@use "sass:math";

.p-steps {
    .p-steps-item {
        .p-menuitem-link {
            background: transparent;
            transition: general.$listItemTransition;
            border-radius: general.$borderRadius;
            background: menu.$stepsItemBg;
            text-decoration: none!important;

            .p-steps-number {
                color: menu.$stepsItemNumberColor;
                border: menu.$stepsItemBorder;
                background: menu.$stepsItemBg;
                min-width: menu.$stepsItemNumberWidth;
                height: menu.$stepsItemNumberHeight;
                line-height: menu.$stepsItemNumberHeight;
                font-size: menu.$stepsItemNumberFontSize;
                z-index: 1;
                border-radius: menu.$stepsItemNumberBorderRadius;
            }

            .p-steps-title {
                margin-top: general.$inlineSpacing;
                color: menu.$stepsItemTextColor;
            }

            &:not(.p-disabled):focus {
                @include mixins.focused();
            }
        }

        &.p-highlight {
            .p-steps-number {
                background: general.$highlightBg;
                color: general.$highlightTextColor;
            }

            .p-steps-title {
                font-weight: menu.$stepsItemActiveFontWeight;
                color: menu.$stepsItemTextColor;
            }
        }

        &:before {
            content:' ';
            border-top: general.$divider;
            width: 100%;
            top: 50%;
            left: 0;
            display: block;
            position: absolute;
            margin-top: -1 * math.div(menu.$stepsItemNumberHeight, 2);
        }

        &.p-disabled {
            opacity: 1;

            &.p-steps-item--is-solved a > * {
                opacity: .4;
            }
        }
    }
}
