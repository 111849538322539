@use "../../../../lib/foundations/typographie/typo.panel.vars" as typopanelvars;
@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/message" as message;
.p-message {
    margin: message.$messageMargin;
    border-radius: general.$borderRadius;

    .p-message-wrapper {
        padding: message.$messagePadding;
    }

    .p-message-close {
        width: general.$actionIconWidth;
        height: general.$actionIconHeight;
        border-radius: general.$actionIconBorderRadius;
        background: transparent;
        transition: general.$actionIconTransition;

        &:hover, &:focus-within {
            background: var(--ig-color-white30);
        }

        &:focus {
            @include mixins.focused();
        }
    }

    &.p-message-info {
        background: message.$infoMessageBg;
        border: message.$infoMessageBorder;
        border-width: message.$messageBorderWidth;
        color: message.$infoMessageTextColor;

        .p-message-icon {
            color: message.$infoMessageIconColor;
        }

        .p-message-close {
            color: message.$infoMessageIconColor;
        }
    }

    &.p-message-success {
        background: message.$successMessageBg;
        border: message.$successMessageBorder;
        border-width: message.$messageBorderWidth;
        color: message.$successMessageTextColor;

        .p-message-icon {
            color: message.$successMessageIconColor;
        }

        .p-message-close {
            color: message.$successMessageIconColor;
        }
    }

    &.p-message-warn {
        background: message.$warningMessageBg;
        border: message.$warningMessageBorder;
        border-width: message.$messageBorderWidth;
        color: message.$warningMessageTextColor;

        .p-message-icon {
            color: message.$warningMessageIconColor;
        }

        .p-message-close {
            color: message.$warningMessageIconColor;
        }
    }

    &.p-message-error {
        background: message.$errorMessageBg;
        border: message.$errorMessageBorder;
        border-width: message.$messageBorderWidth;
        color: message.$errorMessageTextColor;

        .p-message-icon {
            color: message.$errorMessageIconColor;
        }

        .p-message-close {
            color: message.$errorMessageIconColor;
        }
    }

    .p-message-text {
        font-size: message.$messageTextFontSize;
        font-weight: message.$messageTextFontWeight;
    }

    .p-message-icon {
        font-size: message.$messageIconFontSize;
        margin-right: general.$inlineSpacing;
    }

    .p-message-summary {
        font-weight: typopanelvars.$font-weight-bold;
    }

    .p-message-detail {
        margin-left: general.$inlineSpacing;
    }
}
