@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/form" as form;
@use "sass:math";

.p-multiselect {
    background: form.$inputBg;
    border: form.$inputBorder;
    transition: general.$formElementTransition;
    border-radius: general.$borderRadius;

    &:not(.p-disabled):hover,
    &:not(.p-disabled):focus-within {
        border-color: form.$inputHoverBorderColor;
    }

    &:not(.p-disabled).p-focus {
        @include mixins.focused-input();
    }

    .p-multiselect-label {
        padding: form.$inputPadding;
        transition: general.$formElementTransition;

        &.p-placeholder {
            color: form.$inputPlaceholderTextColor;
        }
    }

    &.p-multiselect-chip {
        .p-multiselect-token {
            padding: math.div(nth(form.$inputPadding, 1), 2) nth(form.$inputPadding, 2);
            margin-right: general.$inlineSpacing;
            background: general.$highlightBg;
            color: general.$highlightTextColor;
            border-radius: general.$borderRadius;

            .p-multiselect-token-icon {
                margin-left: general.$inlineSpacing;
            }
        }
    }

    .p-multiselect-trigger {
        background: transparent;
        color: form.$inputIconColor;
        width: form.$inputGroupAddOnMinWidth;
        border-top-right-radius: general.$borderRadius;
        border-bottom-right-radius: general.$borderRadius;
    }

    &.p-error,
    &.p-invalid {
        @include mixins.invalid-input();
    }
}

.p-inputwrapper-filled {
    .p-multiselect {
        &.p-multiselect-chip {
            .p-multiselect-label {
                padding: math.div(nth(form.$inputPadding, 1), 2) nth(form.$inputPadding, 2);
            }
        }
    }
}

.p-multiselect-panel {
    background: form.$inputOverlayBg;
    color: form.$inputListTextColor;
    border: form.$inputOverlayBorder;
    border-radius: general.$borderRadius;
    box-shadow: form.$inputOverlayShadow;

    .p-multiselect-header {
        padding: form.$inputListHeaderPadding;
        border-bottom: form.$inputListHeaderBorder;
        color: form.$inputListHeaderTextColor;
        background: form.$inputOverlayHeaderBg;
        margin: form.$inputListHeaderMargin;
        border-top-right-radius: general.$borderRadius;
        border-top-left-radius: general.$borderRadius;

        .p-multiselect-filter-container {
            .p-inputtext {
                padding-right: nth(form.$inputPadding, 2) + general.$primeIconFontSize;
            }

            .p-multiselect-filter-icon {
                right: nth(form.$inputPadding, 2);
                color: form.$inputIconColor;
            }
        }

        .p-checkbox {
            margin-right: general.$inlineSpacing;
        }

        .p-multiselect-close {
            margin-left: general.$inlineSpacing;
            @include mixins.action-icon();
        }
    }

    .p-multiselect-items {
        padding: form.$inputListPadding;

        .p-multiselect-item {
            margin: form.$inputListItemMargin;
            padding: form.$inputListItemPadding;
            border: form.$inputListItemBorder;
            color: form.$inputListItemTextColor;
            background: form.$inputListItemBg;
            transition: general.$listItemTransition;
            border-radius: form.$inputListItemBorderRadius;

            &.p-highlight {
                color: general.$highlightTextColor;
                background: general.$highlightBg;
            }

            &.p-focus {
              color: general.$highlightTextColor;
              background: general.$highlightBg;
            }

            &:not(.p-highlight):not(.p-disabled):hover,
            &:not(.p-highlight):not(.p-disabled):focus-within {
                color: form.$inputListItemTextHoverColor;
                background: form.$inputListItemHoverBg;
            }

            &:focus {
                @include mixins.focused-listitem();
            }

            .p-checkbox {
                margin-right: general.$inlineSpacing;
            }
        }

        .p-multiselect-empty-message {
            padding: form.$inputListItemPadding;
            color: form.$inputListItemTextColor;
            background: form.$inputListItemBg;
        }
    }
}

p-multiselect.ng-dirty.ng-invalid > .p-multiselect {
    @include mixins.invalid-input();
}

.p-input-filled {
    .p-multiselect {
        background: form.$inputFilledBg;

        &:not(.p-disabled):hover,
        &:not(.p-disabled):focus-within {
            background-color: form.$inputFilledHoverBg;
        }

        &:not(.p-disabled).p-focus {
            background-color: form.$inputFilledFocusBg;
        }
    }
}
