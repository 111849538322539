@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/panel" as panel;
.p-fieldset {
    border: panel.$panelContentBorder;
    background: panel.$panelContentBg;
    color: panel.$panelContentTextColor;
    border-radius: general.$borderRadius;

    .p-fieldset-legend {
        padding: panel.$panelHeaderPadding;
        border: panel.$panelHeaderBorder;
        color: panel.$panelHeaderTextColor;
        background: panel.$panelHeaderBg;
        font-weight: panel.$panelHeaderFontWeight;
        border-radius: general.$borderRadius;
    }

    &.p-fieldset-toggleable {
        .p-fieldset-legend {
            padding: 0;
            transition: general.$actionIconTransition;

            a {
                padding: panel.$panelHeaderPadding;
                color: panel.$panelHeaderTextColor;
                border-radius: general.$borderRadius;
                transition: general.$listItemTransition;

                .p-fieldset-toggler {
                    margin-right: general.$inlineSpacing;
                }

                &:focus {
                    @include mixins.focused();
                }
            }

            &:hover, &:focus-within {
                background: panel.$panelHeaderHoverBg;
                border-color: panel.$panelHeaderHoverBorderColor;
                color: panel.$panelHeaderTextHoverColor;
            }
        }
    }

    .p-fieldset-content {
        padding: panel.$panelContentPadding;
    }
}
