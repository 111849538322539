@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/form" as form;
.p-rating {
    .p-rating-icon {
        color: form.$ratingStarIconOffColor;
        margin-left: general.$inlineSpacing;
        transition: general.$formElementTransition;
        font-size: form.$ratingIconFontSize;

        &.p-rating-cancel {
            color: form.$ratingCancelIconColor;
        }

        &:focus {
            @include mixins.focused();
        }

        &:first-child {
            margin-left: 0;
        }

        &.pi-star {
            color: form.$ratingStarIconOnColor;
        }
    }

    &:not(.p-disabled):not(.p-readonly) {
        .p-rating-icon {
            &:hover, &:focus-within {
                color: form.$ratingStarIconHoverColor;
            }

            &.p-rating-cancel {
                &:hover, &:focus-within {
                    color: form.$ratingCancelIconHoverColor;
                }
            }
        }
    }
}

@if (general.$highlightBg == form.$ratingStarIconOnColor) {
    .p-highlight {
        .p-rating {
            .p-rating-icon {
                color: general.$highlightTextColor;
            }
        }
    }
}
