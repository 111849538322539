@use "variables/general";
@use "sass:meta";

@include meta.load-css('./packages/ingrid/src/scss/ingrid.public.scss');
@include meta.load-css('./packages/ingrid/src/scss/iconfonts.public.scss');
@include meta.load-css('./packages/ingrid/src/scss/a11y.scss');
@include meta.load-css('@shared/loader');

html {
  //min-height: initial;
  //height: initial;
  line-height: 1.5;
}

body {
  /*min-height: 100%;*/
  background-color: var(--ig-color-white);

  &.is-scroll-blocked {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--skin-font-family-headline, var(--skin-font-family)) !important;
}

h6.headline {
  color: var(--h6-color);
  font-family: var(--skin-font-family) !important;
  font-size: 1rem;
  margin-bottom: .25rem;
}

.primary-color {
  color: var(--primary-color);
}

.secondary-color {
  color: var(--secondary-color);
}

mark {
  background-color: var(--secondary-color);
  color: var(--primary-text-color);
}

body.ig-panel label,
body.ig-panel span.label {
  text-transform: none;
  color: var(--ig-color-form-label-color);
}



a.disabled,
a.disabled:hover {
  cursor: not-allowed;
  opacity: general.$disabledOpacity;
}

/*i.tooltip-icon {
  font-style: normal;
  font-weight: normal;
  font-family: 'gridscale_iconfont';
  font-size: 1.5em;
  cursor: help;
  position: relative;
  bottom: -0.125em;
  &:before {
    content: '\E878';
  }
}*/

i[class*="linkextern"] {
  font-size: 1.25em;
  position: relative;
  bottom: -.075em;
}



a.product-documentation-link {
  text-decoration: none;
  padding-bottom: 1rem;
}



.section-label {
  font-size: 16px !important;
}


body.brand ig-empty-state img {
  filter: grayscale(1);
}


/*  workaround:
    primeng changed menu layout (16.1.0)
    and now they add p-disabled class (which includes pointer-events: none) on the menu link (<a>) and on the list item(<li>)
    and so the tooltips don't show anymore for disabled menu items
    additionaly opacity id decreased twice (first on the <a> and then on the <li>)
*/
ul.p-menu-list > li.p-menuitem.p-disabled {
  opacity: 1;
  pointer-events: auto;
}
