@use "../../../variables/data" as data;
@use "../../mixins" as mixins;
@use "../../../variables/form" as form;
@use "../../../variables/general" as general;
@use "../../../variables/panel" as panel;
.p-picklist {
    .p-picklist-buttons {
        padding: panel.$panelContentPadding;

        .p-button {
            margin-bottom: general.$inlineSpacing;
        }
    }

    .p-picklist-header {
        background: panel.$panelHeaderBg;
        color: panel.$panelHeaderTextColor;
        border: panel.$panelHeaderBorder;
        padding: panel.$panelHeaderPadding;
        border-bottom: 0 none;
        border-top-right-radius: general.$borderRadius;
        border-top-left-radius: general.$borderRadius;

        .p-picklist-title {
            font-weight: panel.$panelHeaderFontWeight;
        }
    }

    .p-picklist-filter-container {
        padding: panel.$panelHeaderPadding;
        background: panel.$panelContentBg;
        border: panel.$panelHeaderBorder;
        border-bottom: 0 none;

        .p-picklist-filter-input {
            padding-right: nth(form.$inputPadding, 2) + general.$primeIconFontSize;
        }

        .p-picklist-filter-icon {
            right: nth(form.$inputPadding, 2);
            color: form.$inputIconColor;
        }
    }

    .p-picklist-list {
        border: panel.$panelContentBorder;
        background: panel.$panelContentBg;
        color: panel.$panelContentTextColor;
        padding: form.$inputListPadding;
        border-bottom-right-radius: general.$borderRadius;
        border-bottom-left-radius: general.$borderRadius;

        .p-picklist-item {
            padding: form.$inputListItemPadding;
            margin: form.$inputListItemMargin;
            border: form.$inputListItemBorder;
            color: form.$inputListItemTextColor;
            background: form.$inputListItemBg;
            transition: transform general.$transitionDuration, general.$listItemTransition;

            &:not(.p-highlight):hover,
            &:not(.p-highlight):focus-within {
                background: form.$inputListItemHoverBg;
                color: form.$inputListItemTextHoverColor;
            }

            &:focus {
                @include mixins.focused-listitem();
            }

            &.p-highlight {
                color: general.$highlightTextColor;
                background: general.$highlightBg;
            }
        }

        .p-picklist-droppoint {
            &.p-picklist-droppoint-highlight {
                background-color: var(--primary-color-darker20);
            }
        }

        .p-picklist-empty-message {
            padding: form.$inputListItemPadding;
            color: form.$inputListItemTextColor;
        }
    }
}

@media screen and (max-width: data.$pickListBreakpoint) {
    .p-picklist  {
        flex-direction: column;

        .p-picklist-buttons {
            padding: panel.$panelContentPadding;
            flex-direction: row;

            .p-button {
                margin-right: general.$inlineSpacing;
                margin-bottom: 0;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        // .p-picklist-transfer-buttons {
        //     .pi-angle-right {
        //         @include icon_override('\e930');
        //     }

        //     .pi-angle-double-right {
        //         @include icon_override('\e92c');
        //     }

        //     .pi-angle-left {
        //         @include icon_override('\e933');
        //     }

        //     .pi-angle-double-left {
        //         @include icon_override('\e92f');
        //     }
        // }
    }
}
