@use "../../mixins" as mixins;
@use "../../../variables/general" as general;
@use "../../../variables/misc" as misc;
.p-inplace {
    .p-inplace-display {
        padding: misc.$inplacePadding;
        border-radius: general.$borderRadius;
        transition: general.$formElementTransition;

        &:not(.p-disabled):hover,
        &:not(.p-disabled):focus-within {
            background: misc.$inplaceHoverBg;
            color: misc.$inplaceTextHoverColor;
        }

        &:focus {
            @include mixins.focused();
        }
    }
}

