@use "../../../variables/panel" as panel;
@use "../../mixins" as mixins;
@use "../../../variables/form" as form;
@use "../../../variables/general" as general;
@use "../../../variables/data" as data;
.p-datatable {
    .p-paginator-top {
        border-width: data.$tableTopPaginatorBorderWidth;
        border-radius: 0;
    }

    .p-paginator-bottom {
        border-width: data.$tableBottomPaginatorBorderWidth;
        border-radius: 0;
    }

    .p-datatable-header {
        background: data.$tableHeaderBg;
        color: data.$tableHeaderTextColor;
        border: data.$tableHeaderBorder;
        border-width: data.$tableHeaderBorderWidth;
        padding: data.$tableHeaderPadding;
        font-weight: data.$tableHeaderFontWeight;
    }

    .p-datatable-footer {
        background: data.$tableFooterBg;
        color: data.$tableFooterTextColor;
        border: data.$tableFooterBorder;
        border-width: data.$tableFooterBorderWidth;
        padding: data.$tableFooterPadding;
        font-weight: data.$tableFooterFontWeight;
    }

    .p-datatable-thead > tr > th {
        text-align: data.$tableCellContentAlignment;
        padding: data.$tableHeaderCellPadding;
        border: data.$tableHeaderCellBorder;
        border-width: data.$tableHeaderCellBorderWidth;
        font-weight: data.$tableHeaderCellFontWeight;
        color: data.$tableHeaderCellTextColor;
        background: data.$tableHeaderCellBg;
        transition: general.$listItemTransition;
    }

    .p-datatable-tfoot > tr > td {
        text-align: data.$tableCellContentAlignment;
        padding: data.$tableFooterCellPadding;
        border: data.$tableFooterCellBorder;
        border-width: data.$tableFooterCellBorderWidth;
        font-weight: data.$tableFooterCellFontWeight;
        color: data.$tableFooterCellTextColor;
        background: data.$tableFooterCellBg;
    }

    .p-sortable-column {
        .p-sortable-column-icon {
            color: data.$tableHeaderCellIconColor;
            margin-left: general.$inlineSpacing;
        }

        .p-sortable-column-badge {
            border-radius: 50%;
            height: data.$tableSortableColumnBadgeSize;
            min-width: data.$tableSortableColumnBadgeSize;
            line-height: data.$tableSortableColumnBadgeSize;
            color: general.$highlightTextColor;
            background: general.$highlightBg;
            margin-left: general.$inlineSpacing;
        }

        &:not(.p-highlight):hover,
        &:not(.p-highlight):focus-within {
            background: data.$tableHeaderCellHoverBg;
            color: data.$tableHeaderCellTextHoverColor;

            .p-sortable-column-icon {
                color: data.$tableHeaderCellIconHoverColor;
            }
        }

        &.p-highlight {
            background: data.$tableHeaderCellHighlightBg;
            color: data.$tableHeaderCellHighlightTextColor;

            .p-sortable-column-icon {
                color: data.$tableHeaderCellHighlightTextColor;
            }

            &:hover, &:focus-within {
                background: data.$tableHeaderCellHighlightHoverBg;
                color: data.$tableHeaderCellHighlightTextColor;

                .p-sortable-column-icon {
                    color: data.$tableHeaderCellHighlightTextColor;
                }
            }
        }

        &:focus {
            box-shadow: form.$inputListItemFocusShadow;
            outline: 0 none;
        }
    }

    .p-datatable-tbody {
        > tr {
            background: data.$tableBodyRowBg;
            color: data.$tableBodyRowTextColor;
            transition: general.$listItemTransition;
            outline-color: general.$focusOutlineColor;

            > td {
                text-align: data.$tableCellContentAlignment;
                border: data.$tableBodyCellBorder;
                border-width: data.$tableBodyCellBorderWidth;
                padding: data.$tableBodyCellPadding;

                .p-row-toggler,
                .p-row-editor-init,
                .p-row-editor-save,
                .p-row-editor-cancel {
                    @include mixins.action-icon();
                }

                .p-row-editor-save {
                    margin-right: general.$inlineSpacing;
                }
            }

            &.p-highlight {
                background: general.$highlightBg;
                color: general.$highlightTextColor;
            }

            &.p-datatable-dragpoint-top > td {
                box-shadow: inset 0 2px 0 0 general.$highlightBg;
            }

            &.p-datatable-dragpoint-bottom > td {
                box-shadow: inset 0 -2px 0 0 general.$highlightBg;
            }
        }
    }

    &.p-datatable-hoverable-rows {
        .p-datatable-tbody > tr:not(.p-highlight):hover,
        .p-datatable-tbody > tr:not(.p-highlight):focus-within {
            background: data.$tableBodyRowHoverBg;
            color: data.$tableBodyRowTextHoverColor;
        }
    }

    .p-column-resizer-helper {
        background: data.$tableResizerHelperBg;
    }

    .p-datatable-scrollable-header,
    .p-datatable-scrollable-footer {
        background: panel.$panelHeaderBg;
    }

    .p-datatable-loading-icon {
        font-size: general.$loadingIconFontSize;
    }

    &.p-datatable-gridlines {
        .p-datatable-header {
            border-width: 1px 1px 0 1px;
        }

        .p-datatable-footer {
            border-width: 0 1px 1px 1px;
        }

        .p-paginator-top {
            border-width: 0 1px 0 1px;
        }

        .p-paginator-bottom {
            border-width: 0 1px 1px 1px;
        }

        .p-datatable-thead {
            > tr {
                > th {
                    border-width: 1px 1px nth(data.$tableHeaderCellBorderWidth,3) 1px;
                }
            }
        }

        .p-datatable-tbody {
            > tr {
                > td {
                    border-width: 1px;
                }
            }
        }

        .p-datatable-tfoot {
            > tr {
                > td {
                    border-width: 1px;
                }
            }
        }
    }

    &.p-datatable-striped {
        .p-datatable-tbody {
            > tr:nth-child(even) {
                background: data.$tableBodyRowEvenBg;

                &.p-highlight {
                    background: general.$highlightBg;
                    color: general.$highlightTextColor;

                    .p-row-toggler {
                        color: general.$highlightTextColor;

                        &:hover, &:focus-within {
                            color: general.$highlightTextColor;
                        }
                    }
                }
            }
        }
    }

    &.p-datatable-sm {
        .p-datatable-header {
            @include mixins.scaledPadding(data.$tableHeaderPadding, data.$tableScaleSM);
        }

        .p-datatable-thead > tr > th {
            @include mixins.scaledPadding(data.$tableHeaderCellPadding, data.$tableScaleSM);
        }

        .p-datatable-tbody > tr > td {
            @include mixins.scaledPadding(data.$tableBodyCellPadding, data.$tableScaleSM);
        }

        .p-datatable-tfoot > tr > td {
            @include mixins.scaledPadding(data.$tableFooterPadding, data.$tableScaleSM);
        }

        .p-datatable-footer {
            @include mixins.scaledPadding(data.$tableFooterPadding, data.$tableScaleSM);
        }
    }

    &.p-datatable-lg {
        .p-datatable-header {
            @include mixins.scaledPadding(data.$tableHeaderPadding, data.$tableScaleLG);
        }

        .p-datatable-thead > tr > th {
            @include mixins.scaledPadding(data.$tableHeaderCellPadding, data.$tableScaleLG);
        }

        .p-datatable-tbody > tr > td {
            @include mixins.scaledPadding(data.$tableBodyCellPadding, data.$tableScaleLG);
        }

        .p-datatable-tfoot > tr > td {
            @include mixins.scaledPadding(data.$tableFooterPadding, data.$tableScaleLG);
        }

        .p-datatable-footer {
            @include mixins.scaledPadding(data.$tableFooterPadding, data.$tableScaleLG);
        }
    }
}
